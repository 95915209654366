// Include functions and variables from bootstrap for use in other Sass files
@import "/vendor/custom";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// Animations
$main-mobile-menu-open-speed: 150ms;

// Main header
$main-header-height: 70px;

// Containers
$narrow-container-max-widths: (
    sm: 450px,
    md: 540px,
    lg: 720px,
    xl: 960px,
    xxl: 1140px,
);
