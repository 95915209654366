@use "base/variables";
@use "base/mixins";

//// Select placeholder styling
//select:invalid, select[required][placeholder].form-control > option:first-child {
//  color: variables.$gray-500;
//}
//select:invalid, select[required][placeholder].form-select > option:first-child {
//  color: variables.$gray-500;
//}

// BS5 removed form-group in favor of using utilities like 'mb-3'. Re-add it for compatibility during transition.
.form-group {
    margin-bottom: 1rem;
}

// signup-form is the single-input address form with google maps places autocomplete, found on home page and others.
.signup-form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0.5em;

    @include mixins.media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: center;

        background: variables.$white;
        border-radius: variables.$border-radius;
        padding: 0.25em 0.5em;
    }

    &:focus-within {
        box-shadow: variables.$input-focus-box-shadow;
    }
}

.signup-form__input-container {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background: variables.$white;
    color: variables.$gray-900;
    border-radius: variables.$border-radius;
    padding: 0.25em 0.5em;
}

.signup-form__icon {
    font-size: 0.9em;
}

.signup-form__input {
    border: none;
    background: variables.$white;

    &:focus {
        background: variables.$white;
        box-shadow: none;
    }
}

.signup-form__button {
    padding: 0.5em 3em;
}

.field-errors {
    width: 100%;
    font-size: 80%;
    color: #dc3545;
    list-style: none;
    padding: 0;
    li {
        display: flex;
        gap: 0.5em;
        align-items: center;
    }
}

.unit-select-field {
    margin-top: -1.25rem !important;
    margin-bottom: 4rem;
}

.nice-select {
    border-color: #adb5bd !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    height: 48px !important;
    font-size: 16px !important;
    background-color: transparent !important;
}

div.nice-select-fix {
    height: 38px !important;
    margin-top: 0px !important;
}

.nice-select-dropdown {
    margin: -1px -1px 0px -1px;
}

// This is the honeypot field, it should be hidden to humans, but visible to bots
// It's named this to not be completely obvious to the bot. I.e. not `class="honeypot"`
.home_phone_field {
  position: absolute;
  top: 15px;
  left: 15px;
  transform: translateX(-1000px) translateY(-1000px);
}
