@use "base/variables";
@use "base/mixins";

.customer-network-success-container {
    @include mixins.media-breakpoint-up(lg) {
        width: 55%;
    }
}

.signup-complete-table {
    width: 100%;
    border-collapse: collapse;
    padding: 1em 1em;
    background-color: variables.$white;
    th {
        text-align: left;
        padding: 0.5em 1em;
        background-color: variables.$white;
    }

    td {
        padding: 0.5em 1em;
        background-color: variables.$white;
    }

    tr:last-child td {
        border-bottom: none;
    }

    @include mixins.media-breakpoint-up(lg) {
        width: 30%;
    }
}

.signup-complete-refer-card {
    background-color: variables.$primary;
    color: variables.$white;
    height: 12em;

    .signup-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.6em;
        justify-content: center;
        height: 100%;
    }
}

.signup-complete-refer-card__title {
    font-weight: bold;
    font-size: 1.8em;
    margin-bottom: -0.5em;
}

.signup-complete-refer-card__cta {
    a {
        font-size: 0.7em;
        padding: 0.7em 1.6em;
    }
}
