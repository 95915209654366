@use "base/variables";
@use "base/mixins.scss";

.public-wifi-pm {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.public-wifi-pm__title {
    font-size: 1.1em;
    color: variables.$gray-700;
}

.public-wifi-pm__name {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 2rem;
    color: variables.$gray-700;
}

.public-wifi-pm__logo {
    margin-bottom: 2rem;
    @include mixins.media-breakpoint-up(md) {
        max-height: 100px;
    }
    @include mixins.media-breakpoint-up(lg) {
        max-height: 150px;
    }
}
