@use "base/variables";
@use "base/mixins.scss";

.public-wifi-about {
    display: grid;
    grid-template-areas: "title" "subtitle" "cta";
    text-align: center;
    @include mixins.media-breakpoint-up(sm) {
        grid-template-columns: 2fr 1fr;
        grid-template-areas: "title cta" "subtitle cta";
    }
}

.public-wifi-about__title {
    grid-area: title;
    font-size: 1.1em;
    color: variables.$gray-700;
}

.public-wifi-about__subtitle {
    grid-area: subtitle;
    margin-bottom: 1em;
    @include mixins.media-breakpoint-up(sm) {
        margin-bottom: 0;
    }
    font-family: variables.$font-family-secondary;
    font-size: 0.9em;
    color: variables.$gray-700;
}

.public-wifi-about__cta {
    grid-area: cta;
    justify-self: center;
    align-self: center;
    @include mixins.media-breakpoint-up(md) {
        margin-left: 1em;
    }
}
