@use "base/variables";

.form-errors {
    list-style: none;
    margin: 0;
    padding: 0;
}

.form-errors__item {
    background: variables.$danger;
    color: variables.$white;
    margin-bottom: 0.5em;
    padding: 0.25em 1em;
    border-radius: variables.$alert-border-radius;
}
