@use "base/variables.scss";
@use "base/mixins.scss";

// Single container class with breakpoint max-widths
.container-narrow,
// 100% wide container at all breakpoints
.container-narrow-fluid {
    @include mixins.make-container();
}

// Responsive (narrow) containers that are 100% wide until a breakpoint
@each $breakpoint,
    $container-max-width in variables.$narrow-container-max-widths
{
    .container-narrow-#{$breakpoint} {
        @extend .container-narrow-fluid;
    }

    @include mixins.media-breakpoint-up(
        $breakpoint,
        variables.$grid-breakpoints
    ) {
        %responsive-container-narrow-#{$breakpoint} {
            max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in variables.$grid-breakpoints {
            @if ($extend-breakpoint) {
                .container-narrow#{mixins.breakpoint-infix($name, variables.$grid-breakpoints)} {
                    @extend %responsive-container-narrow-#{$breakpoint};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}
