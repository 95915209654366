@use "base/variables";
@use "base/mixins";

.registration-checkpoint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}
