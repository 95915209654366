@use "base/variables";
@use "base/mixins";

.bbl {
    font-size: small;
    line-height: 1.4;
    border: 1px solid #000;
    float: right;
    padding: .5rem;
    margin-bottom: .5rem;
    background-color: #fff;
    min-width: 250px;
    max-width: 100%;
}

.bbl a,
.bbl a:active,
.bbl a:hover,
.bbl a:visited {
    color: #007bff;
}

.bbl p {
    margin: 0;
}

.bbl section {
    border-bottom: 2px solid #000;
    padding: 0 0 .25rem;
    margin: 0 0 .5rem;
}

.bbl section h4 {
    font-weight: 700;
    font-size: .9rem;
    margin: 0 0 .1rem;
}

.bbl section h4 span,
.bbl section h5 span,
.bbl section ul li span:last-of-type,
.bbl section p span:last-of-type,
.bbl section p a {
    float: right;
    font-weight: 700;
}

.bbl-price-notice {
    font-size: .8rem;
    font-weight: 700;
    margin: 0 0 .5rem;
}

.bbl section h5 {
    font-weight: 400;
    font-size: .8rem;
    padding-left: 1em;
    margin: 0;
}

.bbl section ul {
    padding: 0 0 0 1em;
    margin: 0 0 .5rem;
}

.bbl section ul li {
    list-style-type: none;
    padding: 0 0 0 .8em;
}

.bbl section.header {
    border-bottom-width: 10px;
}

.bbl section.header h1,
h2 {
    font-weight: 700;
}

.bbl section.header h1,
h2,
h3 {
    margin: 0 0 .1rem;
}

.bbl section.header h1 {
    font-size: 2rem;
    border-bottom: 2px solid #000;
}

.bbl section.header h2 {
    font-size: 1.2rem;
}

.bbl section.header h3 {
    font-size: 1rem;
}

.bbl section.header p {
    margin: 0;
}

.bbl section.header p span:last-of-type {
    float: none;
}

.bbl section.billing-period-display {
    border-bottom: 2px solid #000;
    padding: 0 0 .25rem;
    margin: 0 0 .5rem;
}

.bbl section.billing-period-display h4 {
    font-weight: 700;
    font-size: 1rem;
    border-bottom: 5px solid #000;
    margin: 0 0 .1rem;
}

.bbl section.billing-period-display p {
    margin-top: .4em;
}

.bbl section.billing-period-display p span {
    float: none;
    font-weight: 700;
}

.bbl section.billing-period-display p a {
    float: none;
}

.bbl section.additional-charges>h5:nth-of-type(0n+3) {
    margin: 0 0 .5rem;
}

.bbl section.discounts p {
    padding-left: 1em;
    margin-top: .1rem;
}

.bbl section.discounts p a {
    float: none;
}

.bbl section.discounts ul {
    margin: .4em 0 .2em;
}

.bbl section.acp p {
    padding-left: 1em;
}

.bbl section.acp p:last-of-type {
    padding: .2em 0 0 2em;
    font-weight: 700;
}

.bbl section.acp p a {
    float: none;
}

.bbl section.speeds p {
    padding-left: 1em;
}

.bbl section.policies p {
    font-weight: 700;
}

.bbl section.support p {
    padding-left: 1em;
    margin-top: .2em;
}

.bbl section.support p a {
    float: none;
}

.bbl section.footer {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.bbl section.footer p:nth-of-type(0n+2) {
    margin: .2em 0;
}

.bbl section.footer p:nth-of-type(0n+2) a {
    float: right;
}
