@use "base/variables";

.primary-line {
    max-width: 3rem;
    border-color: variables.$primary;
    border-width: 3px;
}

.primary-line--left {
    margin-left: 0;
    margin-right: auto;
}

.primary-line--right {
    margin-left: auto;
    margin-right: 0;
}

.primary-line--white {
    border-color: variables.$white;
}

.primary-line--accent {
    border-color: variables.$accent;
}
