@use "base/variables";

.card-pinstripe-primary {
    border-top: 5px solid variables.$primary;
}

.card-pinstripe-secondary {
    border-top: 5px solid variables.$secondary;
}

.card-pinstripe-success {
    border-top: 5px solid variables.$success;
}

.card-pinstripe-info {
    border-top: 5px solid variables.$info;
}

.card-pinstripe-warning {
    border-top: 5px solid variables.$warning;
}

.card-pinstripe-danger {
    border-top: 5px solid variables.$danger;
}

.card-pinstripe-light {
    border-top: 5px solid variables.$light;
}

.card-pinstripe-dark {
    border-top: 5px solid variables.$dark;
}

.card-pinstripe-accent {
    border-top: 5px solid variables.$accent;
}
