@use "base/variables";
@use "base/mixins";

.registration-flow-header {
    padding-top: variables.$spacer * 3;
    padding-bottom: variables.$spacer * 2;

    a {
        text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.registration-flow-header__text {
    margin-bottom: -0.5em;
    justify-content: space-between;
}

.registration-flow-header__contact {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.3em;
    margin-top: 0.5em;

    @include mixins.media-breakpoint-up(lg) {
        width: auto;
        margin-top: 0;
    }
}
