@use "base/variables";
@use "base/mixins";

.learning-more-card__container {
    width: 100%;
    display: flex;
    justify-content: center;

    @include mixins.media-breakpoint-up(lg) {
        width: auto;
    }
}

.learning-more-card {
    width: 25em;
    height: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: variables.$primary;
    color: variables.$white;
}

.learning-more-card__text {
    align-self: center;
    display: flex;
    width: 90%;
    padding: 2em;
    flex-direction: column;

    p {
        line-height: 1.4em;
        font-size: 0.8em;
    }
}
