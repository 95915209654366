@use "base/variables";
@use "base/mixins";

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-flow: column nowrap;
}

.main-header {
    height: variables.$main-header-height;
    transition: all 250ms ease;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    .andrena-inline-logo {
        color: variables.$primary;
        transition: color 250ms ease;

        // Account for the lowercase 'd' making the logo not look centered.
        position: relative;
        top: -2px;
    }

    #lt,
    #gt {
        color: variables.$accent;
    }

    .main-header__mobilecta {
        display: none;

        // Control the exact viewport width that this button appears. Does not conform to a BS breakpoint.
        @media (min-width: 23em) {
            display: block;
        }

        @include mixins.media-breakpoint-up(lg) {
            display: none;
        }
    }

    .navbar-toggler {
        background: variables.$white;
        padding: 0.375em 0.6em;
        border: none;
    }
}

// Light variant of the header for display in front of dark backgrounds
.main-header.light {
    .andrena-inline-logo {
        color: variables.$white;
    }
}

.main-header.dark {
    #lt,
    #gt {
        color: variables.$white;
    }
}

// When the user scrolls down, the header will always be white background with dark text
.main-header.scrolled {
    background: variables.$white;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);

    .andrena-inline-logo {
        color: variables.$primary;
    }

    #lt,
    #gt {
        color: variables.$accent;
    }

    .navbar-toggler {
        background: variables.$primary;
        color: variables.$accent;
    }

    .header-accent_button.btn-primary {
        color: variables.$white;
        background: variables.$primary;
    }
}

.main-header #sign-up-btn {
    white-space: nowrap;
    width: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.25s ease;
    margin-left: -0.5rem;

    .btn {
        display: block;
        padding-left: 1.75em;
        padding-right: 1.75em;
    }
}

.main-header.scrolled-far #sign-up-btn {
    width: 7.5em;
    opacity: 1;
    margin-left: 0;
}

.main-desktop-menu {
    .navbar-nav {
        gap: 0.5em;
    }

    .nav-link,
    .btn {
        transition: color 250ms ease;
    }

    .nav-link {
        font-style: italic;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;

        &:hover,
        &:focus {
            border-bottom-color: variables.$accent;
        }
    }

    .main-header.light & .nav-link {
        color: variables.$white;

        &:hover,
        &:focus {
            border-bottom-color: variables.$accent;
        }
    }

    .main-header.scrolled & .nav-link {
        color: variables.$gray-900;

        &:hover,
        &:focus {
            border-bottom-color: variables.$accent;
        }
    }
}

.main-mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    transform: translateX(100%);
    // z-index of bootstrap modals
    z-index: 1055;

    &.transitioning {
        transition: transform variables.$main-mobile-menu-open-speed ease-in-out;
    }

    &.open {
        transform: translateX(0%);
    }

    @include mixins.media-breakpoint-up(lg) {
        display: none;
    }

    .navbar-brand {
        // Account for the lowercase 'd' making the logo not look centered.
        position: relative;
        top: -2px;
    }

    .nav-link {
        color: variables.$white;
    }
}

.main-mobile-menu__header {
    padding: 0.75rem 0.75rem 0;
}

.main-mobile-menu__ctabtns {
    border-bottom: 2px solid rgba(variables.$white, 0.2);
    padding-bottom: 1.5em;
}

.main-mobile-menu__content {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
}

.main-mobile-menu__body {
    flex: 1;
    padding: 1rem 0.75rem;
}

.main-mobile-menu__nav1,
.main-mobile-menu__nav2 {
    .nav-link {
        padding: 0.5em 0;
    }
}

.main-mobile-menu__nav1 {
    font-style: italic;
    font-size: 1.5em;
}

.main-mobile-menu__nav2 {
    font-size: 0.875em;
    opacity: 0.75;
}

.main-mobile-closebtn {
    padding: 0.375em 0.7em;
}

.main-mobile-menu__footer {
    border-top: 2px solid rgba(variables.$white, 0.2);
    padding: 1.5rem 0.75rem;
    margin-top: 1.5em;
}

.main-mobile-menu__footernav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5em;

    .nav-link {
        padding: 0;

        img {
            height: 2.5em;
            width: auto;
        }
    }
}

.main-mobile-menu__footercopyright {
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    font-size: 0.75em;
    gap: 0 0.5em;
    color: variables.$gray-500;
}

.main-content {
    flex: 1;
    padding-top: variables.$main-header-height;

    &.no-padding {
        padding-top: 0;
    }
}

.main-footer {
    background: variables.$primary;
    color: variables.$white;
    font-size: 0.8rem;
}

.main-footer__body {
    display: grid;
    grid-template-columns: minmax(4em, 6em) auto max-content;
    gap: 1em;
    padding: 5em 0;
}

.main-footer__menu {
    display: grid;
    grid-template-columns: 1fr;
    justify-self: center;
    gap: 0.5em 2em;

    @include mixins.media-breakpoint-up(md) {
        grid-template-columns: repeat(2, auto);
        grid-auto-flow: column;
        justify-self: auto;
        padding: 0 2em;

        // Special placement of links in the first column
        .link-about-us,
        .link-faq,
        .link-contact-us {
            grid-column: 1;
        }

        // Special placement of links in the second column
        .link-for-residents,
        .link-for-buildings {
            grid-column: 2;
        }

        // Special placement of links in the third column
        .link-network-management-policy,
        .link-privacy-policy,
        .link-terms {
            grid-column: 3;
        }
    }

    @include mixins.media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, auto);
        padding: 0 4em;

        // Special placement of links in the first column
        .link-about-us,
        .link-faq,
        .link-contact-us {
            grid-column: 1;
        }

        // Special placement of links in the second column
        .link-for-residents,
        .link-for-buildings {
            grid-column: 2;
        }

        // Special placement of links in the third column
        .link-network-management-policy,
        .link-privacy-policy,
        .link-terms {
            grid-column: 3;
        }
    }

    @include mixins.media-breakpoint-up(xl) {
        padding: 0 8em;
    }

    @include mixins.media-breakpoint-up(xxl) {
        padding: 0 12em;
    }
}

.main-footer__link {
    color: variables.$gray-400;
    text-decoration: none;

    &:hover,
    &:focus {
        color: variables.$white;
    }
}

.main-footer__footer {
    border-top: 2px solid rgba(variables.$white, 0.2);
    padding: 1.5rem 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.main-footer__footernav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5em;

    .nav-link {
        padding: 0;

        img {
            height: 2.5em;
            width: auto;
        }
    }
}

.main-footer__footercopyright {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    gap: 0 0.5em;
    color: variables.$gray-500;
}
