@use "base/variables";

.please-wait {
    position: relative;
    text-align: center;
    padding: 5em 2em 4em;
    font-size: 2rem;
    color: variables.$gray-600;
}

.please-wait::before {
    display: block;
    content: "";
    z-index: 10001;
    background-image: url("/assets/images/rotating-icon.svg");
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: absolute;
    top: 4em;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: spin-icon 2s linear infinite;
}

@keyframes spin-icon {
    from {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}
