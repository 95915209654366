@use "base/variables";
@use "base/mixins";

.radio-boxes {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: -0.5rem;

    input[type="radio"] {
        position: absolute;
        width: 1px;
        height: 1px;
        left: -9999px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    .radio-box-label {
        flex: 1;
        padding: 1rem 1rem;
        border: 1px solid variables.$gray-300;
        margin: 0.5rem;
        cursor: pointer;
        border-radius: variables.$border-radius;
        text-align: center;
        min-width: 15em;
    }

    input[type="radio"]:checked + .radio-box-label {
        border-color: variables.$primary;
        box-shadow: 0 0 0 1px variables.$primary;
    }

    input[type="radio"]:focus + .radio-box-label {
        border-color: variables.$primary;
        outline: 0;
        box-shadow: variables.$input-box-shadow,
            variables.$input-focus-box-shadow;
    }
}
