@use "base/variables";
@use "base/mixins.scss";

.service-box-container {
    max-width: 50em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: variables.$white;
    margin-bottom: 0.8em;
    border-radius: 8px;
    border: 1px solid gray;
    position: relative;
    input {
        opacity: 0;
        position: absolute;
    }
    input:focus {
        & ~ .background-container {
            background: #07292b;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        & ~ label {
            z-index: 2;
        }
    }
    transition: box-shadow 0.3s ease;
}

.service-box-container-hover:hover {
    cursor: pointer;
}

.service-box-container-hover:not(.selected-plan):hover {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.service-box-price {
    display: flex;
    justify-content: space-between;
    margin-top: -0.5em;

    span {
        font-weight: 400;
        font-size: 2em;
    }
}

.service-box-price__container {
    display: flex;
    align-items: center;
    gap: 0.2em;
}

.service-box__upto {
    font-size: 0.8em;
}

.custom-accordion-button {
    display: flex;
    align-items: center;

    a {
        order: 3;
        text-decoration: none;
        color: #c80542;
        font-size: 0.8em;
        font-weight: bold;
        display: none;
    }
}

.custom-accordion-button[aria-expanded="true"] a {
    display: inline;
}

.custom-accordion-button__text {
    order: 2;
    flex-grow: 1;
}

.custom-accordion-button::after {
    margin-right: 0.5rem;
    order: 1;
    background-image: url("/assets/images/right_arrow.svg") !important;
}

.custom-accordion-button:not(.collapsed):after {
    background-image: url("/assets/images/down_arrow.svg") !important;
}
.service-box-container input:focus ~ *,
.selected-plan {
    background-color: variables.$primary;
    color: variables.$white;
}

.service-box-label {
    padding: 0.6em 1.3em;
    cursor: pointer;
}
