// This is custom SCSS that overrides the built-in bootstrap variables.  It is included in the vendor.scss file.

//@import 'bootstrap/scss/functions';
//@import "bootstrap/scss/variables";

// Color system
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: #07292b;
$danger: #d52e21;
$accent: #f9c939;
$accent-dark: #654a00;

$border-color: $gray-500;

// Body
$body-bg: $gray-100;
$body-color: $gray-900;

// Typography
$link-color: $body-color;

// Buttons
$input-btn-border-width: 0px;

// Accordion
$accordion-button-icon: url(/assets/images/right_arrow.svg);
$accordion-button-active-icon: url(/assets/images/down_arrow.svg);
$accordion-icon-transform: rotate(0);
$accordion-padding-y: 1rem;
$accordion-bg: $white;
$accordion-border-width: 0;
$accordion-button-active-bg: none;
$accordion-button-focus-box-shadow: none;
$accordion-button-padding-x: 0;
$accordion-body-padding-x: 0;

// Inputs
$input-btn-border-width: 1px;
//$input-focus-box-shadow: none;

// Fonts
$font-family-sans-serif:
    Lato,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol" !default;
$font-family-secondary: "Montserrat", $font-family-sans-serif;


// Transitions
$transition-fast: all 100ms ease-in-out;
$menu-open-speed: 150ms;

// Header
$header-height: 70px;