@use "base/variables";
@use "base/mixins";

.payment-form {
    @include mixins.media-breakpoint-up(md) {
        width: 43.5em;
    }

    @include mixins.media-breakpoint-up(lg) {
        width: auto;
    }
    margin-bottom: 0.8em;
}

.payment-form__card__container {
    width: 100%;
    height: 15em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: variables.$primary;
    padding-bottom: 0.4em;
    margin-bottom: 2em;

    @include mixins.media-breakpoint-up(sm) {
        width: 32em;
    }

    @include mixins.media-breakpoint-up(md) {
        width: 43.5em;
    }

    @include mixins.media-breakpoint-up(lg) {
        width: 100%;
    }
}

.payment-form__img__container {
    color: variables.$white;
    margin-bottom: 0.7em;
}

.custom-control-input {
    box-shadow: none;
}

#card-element {
    padding: 0.7em;
}

.payment-form__card {
    width: 80%;
}
