@use "base/variables";
@use "base/mixins";

.progress-steps {
    display: flex;
    flex-flow: row nowrap;
    gap: 1px;
    width: 100%;

    div.active {
        background-color: variables.$accent;
    }
}

.step {
    height: 3px;
    width: 100%;
    background-color: variables.$gray-300;
}
