@use "base/variables";

.header-line {
    position: relative;
    text-align: center;

    &:before {
        content: "";
        background: variables.$gray-500;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
    }

    span {
        background: variables.$body-bg;
        padding: 0 1em;
        position: relative;
        z-index: 1;
    }
}
