@use "base/variables";
@use "base/mixins";

.password-field {
}

.password-field__toggle {
    @include mixins.hide-control;
}

.password-field__label {
    margin: 0;
    cursor: pointer;
}

.password-field__toggle:focus ~ .password-field__label {
    background-color: variables.$gray-300;
}

.password-field__toggle ~ .password-field__label:before {
    content: "\f06e";
}
.password-field__toggle:checked ~ .password-field__label:before {
    content: "\f070";
}
