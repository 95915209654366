@use "base/variables";

.bg-accent {
    background-color: variables.$accent;
}

[data-text-reveal] {
    font-kerning: none;
}

.sep {
    display: inline-block;
    vertical-align: middle;
    width: 3em;
    height: 2px;
    background: variables.$gray-900;

    &.sep-primary {
        background: variables.$primary;
    }

    &.sep-accent {
        background: variables.$accent;
    }

    &.sep-white {
        background: variables.$white;
    }
}
