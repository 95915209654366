@use "base/variables.scss";

// Brand text that mimics the logo/wordmark. This is a flex so it can be used with the angle brackets: lt.svg, gt.svg
.text-brand {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: baseline;
    font-family: "Boing", sans-serif;
    font-weight: 700;
    height: 1em;

    &__lt,
    &__gt {
        display: block;
        height: 100%;
        width: auto;
        position: relative;
        top: 0.2em;
    }

    // These are to pull the angle brackets in a little bit, and should only be used with round characters, such as
    // 'a' or 'o'. e.g. <andrena> would pull just the left less-than bracket in close.
    &__lt.close {
        margin-right: -0.1em;
    }

    &__gt.close {
        margin-left: -0.1em;
    }
}

.text-small {
    font-size: 0.9em;
}

.text-x-small {
    font-size: 0.8em;
}
