.public-wifi-grid {
    display: grid;
    grid-template-areas: "prompt" "message" "about";
    grid-gap: 1em;
    text-align: center;

    .dialog {
        min-width: auto;
        max-width: none;
        width: 100%;
        height: 100%;
    }
}

.public-wifi-grid__prompt {
    grid-area: prompt;
}

.public-wifi-grid__about {
    grid-area: about;
}

.public-wifi-grid__property-manager {
    grid-area: pm;
}

.public-wifi-grid__message {
    grid-area: message;
}

.public-wifi-grid__messagetext {
    p:last-child {
        margin-bottom: 0;
    }
}
