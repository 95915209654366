@use "base/mixins.scss";

.support-options {
    @include mixins.media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
    }
    @include mixins.media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.support-options__card {
    margin-bottom: 1em;
}

.support-options__chat {
    @include mixins.media-breakpoint-up(sm) {
        grid-column: 1/3;
    }
    @include mixins.media-breakpoint-up(lg) {
        grid-column: auto;
    }
}
