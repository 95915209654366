body {
  font-family: Okta Neue, sans-serif;
}

.btn-accent, .header-accent_button {
  color: #07292b;
  background: #f9c939;
}

.btn-accent:hover, .header-accent_button:hover, .btn-accent:focus, .header-accent_button:focus {
  color: #07292b;
  background-color: #f8c220;
}

.btn-outline-accent {
  color: #9e7700;
  background-color: #0000;
  background-image: none;
  border-color: #f9c939;
}

.btn-outline-accent:hover {
  color: #654a00;
  background-color: #f9c939;
}

.form-group {
  margin-bottom: 1rem;
}

.signup-form {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: .5em;
  display: flex;
}

@media (min-width: 576px) {
  .signup-form {
    background: #fff;
    border-radius: .375rem;
    flex-direction: row;
    align-items: center;
    padding: .25em .5em;
  }
}

.signup-form:focus-within {
  box-shadow: 0 0 0 .25rem #07292b40;
}

.signup-form__input-container {
  color: #212529;
  background: #fff;
  border-radius: .375rem;
  flex-flow: row;
  flex: 1;
  align-items: center;
  padding: .25em .5em;
  display: flex;
}

.signup-form__icon {
  font-size: .9em;
}

.signup-form__input {
  background: #fff;
  border: none;
}

.signup-form__input:focus {
  box-shadow: none;
  background: #fff;
}

.signup-form__button {
  padding: .5em 3em;
}

.field-errors {
  color: #dc3545;
  width: 100%;
  padding: 0;
  font-size: 80%;
  list-style: none;
}

.field-errors li {
  align-items: center;
  gap: .5em;
  display: flex;
}

.unit-select-field {
  margin-bottom: 4rem;
  margin-top: -1.25rem !important;
}

.nice-select {
  background-color: #0000 !important;
  border-color: #adb5bd !important;
  border-radius: 8px !important;
  align-items: center !important;
  height: 48px !important;
  font-size: 16px !important;
  display: flex !important;
}

div.nice-select-fix {
  height: 38px !important;
  margin-top: 0 !important;
}

.nice-select-dropdown {
  margin: -1px -1px 0;
}

.home_phone_field {
  position: absolute;
  top: 15px;
  left: 15px;
  transform: translateX(-1000px)translateY(-1000px);
}

.container-narrow, .container-narrow-fluid, .container-narrow-xxl, .container-narrow-xl, .container-narrow-lg, .container-narrow-md, .container-narrow-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-narrow-sm, .container-narrow {
    max-width: 450px;
  }
}

@media (min-width: 768px) {
  .container-narrow-md, .container-narrow-sm, .container-narrow {
    max-width: 540px;
  }
}

@media (min-width: 992px) {
  .container-narrow-lg, .container-narrow-md, .container-narrow-sm, .container-narrow {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .container-narrow-xl, .container-narrow-lg, .container-narrow-md, .container-narrow-sm, .container-narrow {
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .container-narrow-xxl, .container-narrow-xl, .container-narrow-lg, .container-narrow-md, .container-narrow-sm, .container-narrow {
    max-width: 1140px;
  }
}

.bg-accent {
  background-color: #f9c939;
}

[data-text-reveal] {
  font-kerning: none;
}

.sep {
  vertical-align: middle;
  background: #212529;
  width: 3em;
  height: 2px;
  display: inline-block;
}

.sep.sep-primary {
  background: #07292b;
}

.sep.sep-accent {
  background: #f9c939;
}

.sep.sep-white {
  background: #fff;
}

.radio-boxes {
  flex-flow: wrap;
  justify-content: space-between;
  margin: -.5rem;
  display: flex;
}

.radio-boxes input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.radio-boxes .radio-box-label {
  cursor: pointer;
  text-align: center;
  border: 1px solid #dee2e6;
  border-radius: .375rem;
  flex: 1;
  min-width: 15em;
  margin: .5rem;
  padding: 1rem;
}

.radio-boxes input[type="radio"]:checked + .radio-box-label {
  border-color: #07292b;
  box-shadow: 0 0 0 1px #07292b;
}

.radio-boxes input[type="radio"]:focus + .radio-box-label {
  border-color: #07292b;
  outline: 0;
  box-shadow: inset 0 1px 2px #00000013, 0 0 0 .25rem #07292b40;
}

.slider-set {
  position: relative;
  overflow: hidden;
}

.slider-set-item {
  z-index: -1;
  opacity: 0;
  white-space: nowrap;
  position: absolute;
  inset: 0;
}

.slider-set-item.active {
  z-index: 1;
  opacity: 1;
  position: relative;
}

.slider-progress {
  flex-flow: row;
  gap: 2px;
  display: flex;
}

.slider-progress-indicator {
  --progress: 0%;
  background: #21252940;
  flex: 1;
  height: .125rem;
  position: relative;
}

.slider-progress-indicator:before {
  content: "";
  width: var(--progress);
  background: #212529;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.text-brand {
  flex-flow: row;
  align-items: baseline;
  height: 1em;
  font-family: Boing, sans-serif;
  font-weight: 700;
  display: inline-flex;
}

.text-brand__lt, .text-brand__gt {
  width: auto;
  height: 100%;
  display: block;
  position: relative;
  top: .2em;
}

.text-brand__lt.close {
  margin-right: -.1em;
}

.text-brand__gt.close {
  margin-left: -.1em;
}

.text-x-small {
  font-size: .8em;
}

.dialog {
  align-items: center;
  min-width: 90%;
  max-width: 90%;
  height: 64vh;
  display: flex;
}

.dialog a {
  text-decoration: none;
}

@media (min-width: 576px) {
  .dialog {
    min-width: 35rem;
    max-width: 70%;
  }

  .dialog.-fixed {
    min-width: 80%;
    max-width: 80%;
  }
}

@media (min-width: 768px) {
  .dialog.-fixed {
    min-width: 70%;
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .dialog.-fixed {
    min-width: 60%;
    max-width: 60%;
  }
}

.dialog-form-container {
  width: 28em;
}

@media (min-width: 992px) {
  .dialog-form-container {
    width: 35em;
  }
}

.card-body {
  min-width: 100%;
}

.dialog.card .card-body.text-center {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
}

.card-pinstripe-primary {
  border-top: 5px solid #07292b;
  height: auto !important;
}

.learning-more-card__container {
  justify-content: center;
  width: 100%;
  display: flex;
}

@media (min-width: 992px) {
  .learning-more-card__container {
    width: auto;
  }
}

.learning-more-card {
  color: #fff;
  background: #07292b;
  flex-direction: column;
  justify-content: center;
  width: 25em;
  height: 10em;
  display: flex;
}

.learning-more-card__text {
  flex-direction: column;
  align-self: center;
  width: 90%;
  padding: 2em;
  display: flex;
}

.learning-more-card__text p {
  font-size: .8em;
  line-height: 1.4em;
}

html, body {
  height: 100%;
}

body {
  flex-flow: column;
  display: flex;
}

.main-header {
  height: 70px;
  transition: all .25s;
  box-shadow: 0 0 1px #0000;
}

.main-header .andrena-inline-logo {
  color: #07292b;
  transition: color .25s;
  position: relative;
  top: -2px;
}

.main-header #lt, .main-header #gt {
  color: #f9c939;
}

.main-header .main-header__mobilecta {
  display: none;
}

@media (min-width: 23em) {
  .main-header .main-header__mobilecta {
    display: block;
  }
}

@media (min-width: 992px) {
  .main-header .main-header__mobilecta {
    display: none;
  }
}

.main-header .navbar-toggler {
  background: #fff;
  border: none;
  padding: .375em .6em;
}

.main-header.light .andrena-inline-logo, .main-header.dark #lt, .main-header.dark #gt {
  color: #fff;
}

.main-header.scrolled {
  background: #fff;
  box-shadow: 0 0 0 2px #0000000d;
}

.main-header.scrolled .andrena-inline-logo {
  color: #07292b;
}

.main-header.scrolled #lt, .main-header.scrolled #gt {
  color: #f9c939;
}

.main-header.scrolled .navbar-toggler {
  color: #f9c939;
  background: #07292b;
}

.main-header.scrolled .header-accent_button.btn-primary {
  color: #fff;
  background: #07292b;
}

.main-header #sign-up-btn {
  white-space: nowrap;
  opacity: 0;
  width: 0;
  margin-left: -.5rem;
  transition: all .25s;
  overflow: hidden;
}

.main-header #sign-up-btn .btn {
  padding-left: 1.75em;
  padding-right: 1.75em;
  display: block;
}

.main-header.scrolled-far #sign-up-btn {
  opacity: 1;
  width: 7.5em;
  margin-left: 0;
}

.main-desktop-menu .navbar-nav {
  gap: .5em;
}

.main-desktop-menu .nav-link, .main-desktop-menu .btn {
  transition: color .25s;
}

.main-desktop-menu .nav-link {
  border-top: 3px solid #0000;
  border-bottom: 3px solid #0000;
  font-style: italic;
}

.main-desktop-menu .nav-link:hover, .main-desktop-menu .nav-link:focus {
  border-bottom-color: #f9c939;
}

.main-header.light .main-desktop-menu .nav-link {
  color: #fff;
}

.main-header.light .main-desktop-menu .nav-link:hover, .main-header.light .main-desktop-menu .nav-link:focus {
  border-bottom-color: #f9c939;
}

.main-header.scrolled .main-desktop-menu .nav-link {
  color: #212529;
}

.main-header.scrolled .main-desktop-menu .nav-link:hover, .main-header.scrolled .main-desktop-menu .nav-link:focus {
  border-bottom-color: #f9c939;
}

.main-mobile-menu {
  z-index: 1055;
  flex-flow: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
}

.main-mobile-menu.transitioning {
  transition: transform .15s ease-in-out;
}

.main-mobile-menu.open {
  transform: translateX(0%);
}

@media (min-width: 992px) {
  .main-mobile-menu {
    display: none;
  }
}

.main-mobile-menu .navbar-brand {
  position: relative;
  top: -2px;
}

.main-mobile-menu .nav-link {
  color: #fff;
}

.main-mobile-menu__header {
  padding: .75rem .75rem 0;
}

.main-mobile-menu__ctabtns {
  border-bottom: 2px solid #fff3;
  padding-bottom: 1.5em;
}

.main-mobile-menu__content {
  flex-flow: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.main-mobile-menu__body {
  flex: 1;
  padding: 1rem .75rem;
}

.main-mobile-menu__nav1 .nav-link, .main-mobile-menu__nav2 .nav-link {
  padding: .5em 0;
}

.main-mobile-menu__nav1 {
  font-size: 1.5em;
  font-style: italic;
}

.main-mobile-menu__nav2 {
  opacity: .75;
  font-size: .875em;
}

.main-mobile-closebtn {
  padding: .375em .7em;
}

.main-mobile-menu__footer {
  border-top: 2px solid #fff3;
  margin-top: 1.5em;
  padding: 1.5rem .75rem;
}

.main-mobile-menu__footernav {
  flex-flow: row;
  align-items: center;
  gap: .5em;
  display: flex;
}

.main-mobile-menu__footernav .nav-link {
  padding: 0;
}

.main-mobile-menu__footernav .nav-link img {
  width: auto;
  height: 2.5em;
}

.main-mobile-menu__footercopyright {
  text-align: right;
  color: #adb5bd;
  flex-flow: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0 .5em;
  margin-left: auto;
  font-size: .75em;
  display: flex;
}

.main-content {
  flex: 1;
  padding-top: 70px;
}

.main-content.no-padding {
  padding-top: 0;
}

.main-footer {
  color: #fff;
  background: #07292b;
  font-size: .8rem;
}

.main-footer__body {
  grid-template-columns: minmax(4em, 6em) auto max-content;
  gap: 1em;
  padding: 5em 0;
  display: grid;
}

.main-footer__menu {
  grid-template-columns: 1fr;
  justify-self: center;
  gap: .5em 2em;
  display: grid;
}

@media (min-width: 768px) {
  .main-footer__menu {
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    justify-self: auto;
    padding: 0 2em;
  }

  .main-footer__menu .link-about-us, .main-footer__menu .link-faq, .main-footer__menu .link-contact-us {
    grid-column: 1;
  }

  .main-footer__menu .link-for-residents, .main-footer__menu .link-for-buildings {
    grid-column: 2;
  }

  .main-footer__menu .link-network-management-policy, .main-footer__menu .link-privacy-policy, .main-footer__menu .link-terms {
    grid-column: 3;
  }
}

@media (min-width: 992px) {
  .main-footer__menu {
    grid-template-columns: repeat(3, auto);
    padding: 0 4em;
  }

  .main-footer__menu .link-about-us, .main-footer__menu .link-faq, .main-footer__menu .link-contact-us {
    grid-column: 1;
  }

  .main-footer__menu .link-for-residents, .main-footer__menu .link-for-buildings {
    grid-column: 2;
  }

  .main-footer__menu .link-network-management-policy, .main-footer__menu .link-privacy-policy, .main-footer__menu .link-terms {
    grid-column: 3;
  }
}

@media (min-width: 1200px) {
  .main-footer__menu {
    padding: 0 8em;
  }
}

@media (min-width: 1400px) {
  .main-footer__menu {
    padding: 0 12em;
  }
}

.main-footer__link {
  color: #ced4da;
  text-decoration: none;
}

.main-footer__link:hover, .main-footer__link:focus {
  color: #fff;
}

.main-footer__footer {
  border-top: 2px solid #fff3;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  display: flex;
}

.main-footer__footernav {
  flex-flow: row;
  align-items: center;
  gap: .5em;
  display: flex;
}

.main-footer__footernav .nav-link {
  padding: 0;
}

.main-footer__footernav .nav-link img {
  width: auto;
  height: 2.5em;
}

.main-footer__footercopyright {
  text-align: right;
  color: #adb5bd;
  flex-flow: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 0 .5em;
  display: flex;
}

.main-content--fullscreen {
  height: calc(100vh - 70px);
}

.main-content--dialog {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-content--background {
  background: linear-gradient(#07292b66 0%, #07292b33 50%), url("../cityscape2.45348fd1.jpg") center / cover no-repeat;
}

.main-content--parallax {
  perspective: 1px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.messages {
  z-index: 1100;
  width: 100%;
  max-width: 30em;
  padding: 1em;
  position: fixed;
  bottom: 0;
  left: 0;
}

.partners-marquee {
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .partners-marquee {
    flex-direction: row;
    align-items: center;
    gap: 1em;
    padding: 2em;
  }
}

.partners-marquee h2 {
  text-align: center;
  color: #07292b;
  white-space: nowrap;
  padding-bottom: 1em;
  font-size: .8em;
  font-weight: bold;
}

@media (min-width: 576px) {
  .partners-marquee h2 {
    padding-bottom: 0;
    padding-left: 2em;
    font-size: 1em;
  }
}

.partners-marquee__container {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.partners-marquee__container:before {
  content: "";
  z-index: 1;
  background: linear-gradient(to right, #f7f7f7 0%, #f7f7f700 100%);
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.partners-marquee__container:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to left, #f7f7f7 0%, #f7f7f700 100%);
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.partners-marquee__img {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 6em;
  padding: 0 2.5em;
  display: flex;
  position: relative;
}

.partners-marquee__img img {
  height: 100%;
}

.password-field__toggle {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  left: -9999px;
  overflow: hidden;
}

.password-field__label {
  cursor: pointer;
  margin: 0;
}

.password-field__toggle:focus ~ .password-field__label {
  background-color: #dee2e6;
}

.password-field__toggle ~ .password-field__label:before {
  content: "";
}

.password-field__toggle:checked ~ .password-field__label:before {
  content: "";
}

.payment-form {
  margin-bottom: .8em;
}

@media (min-width: 768px) {
  .payment-form {
    width: 43.5em;
  }
}

@media (min-width: 992px) {
  .payment-form {
    width: auto;
  }
}

.payment-form__card__container {
  background: #07292b;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15em;
  margin-bottom: 2em;
  padding-bottom: .4em;
  display: flex;
}

@media (min-width: 576px) {
  .payment-form__card__container {
    width: 32em;
  }
}

@media (min-width: 768px) {
  .payment-form__card__container {
    width: 43.5em;
  }
}

@media (min-width: 992px) {
  .payment-form__card__container {
    width: 100%;
  }
}

.payment-form__img__container {
  color: #fff;
  margin-bottom: .7em;
}

.custom-control-input {
  box-shadow: none;
}

#card-element {
  padding: .7em;
}

.payment-form__card {
  width: 80%;
}

.progress-steps {
  flex-flow: row;
  gap: 1px;
  width: 100%;
  display: flex;
}

.progress-steps div.active {
  background-color: #f9c939;
}

.step {
  background-color: #dee2e6;
  width: 100%;
  height: 3px;
}

.promotional-card {
  color: #fff;
  background: #07292b;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22em;
  height: 11.2em;
  display: flex;
}

.promotional-card__text {
  flex-direction: column;
  align-self: center;
  gap: .2em;
  width: 100%;
  display: flex;
}

.promotional-card__text p {
  align-self: center;
  margin-left: -1em;
  line-height: 1.4em;
}

.promotional-card__text small {
  text-align: center;
  width: 82%;
}

.promotional-card__accordion {
  align-self: center;
  align-items: center;
  gap: .5em;
  width: 90%;
  padding-left: .2em;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
}

.promotional-card__accordion a {
  color: #fff;
  text-decoration: none;
}

.registration-flow-header {
  flex-direction: column;
  gap: .8em;
  padding-top: 3rem;
  padding-bottom: 2rem;
  display: flex;
}

.registration-flow-header a {
  text-decoration: none;
}

.registration-flow-header__text {
  justify-content: space-between;
  margin-bottom: -.5em;
}

.registration-flow-header__contact {
  justify-content: flex-end;
  align-items: center;
  gap: .3em;
  width: 100%;
  margin-top: .5em;
  display: flex;
}

@media (min-width: 992px) {
  .registration-flow-header__contact {
    width: auto;
    margin-top: 0;
  }
}

.service-box-container {
  background: #fff;
  border: 1px solid gray;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 50em;
  margin-bottom: .8em;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.service-box-container input {
  opacity: 0;
  position: absolute;
}

.service-box-container input:focus ~ .background-container {
  background: #07292b;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.service-box-container input:focus ~ label {
  z-index: 2;
}

.service-box-container-hover:hover {
  cursor: pointer;
}

.service-box-container-hover:not(.selected-plan):hover {
  box-shadow: 0 0 0 3px #0000001a;
}

.service-box-price {
  justify-content: space-between;
  margin-top: -.5em;
  display: flex;
}

.service-box-price span {
  font-size: 2em;
  font-weight: 400;
}

.service-box-price__container {
  align-items: center;
  gap: .2em;
  display: flex;
}

.service-box__upto {
  font-size: .8em;
}

.custom-accordion-button {
  align-items: center;
  display: flex;
}

.custom-accordion-button a {
  color: #c80542;
  order: 3;
  font-size: .8em;
  font-weight: bold;
  text-decoration: none;
  display: none;
}

.custom-accordion-button[aria-expanded="true"] a {
  display: inline;
}

.custom-accordion-button__text {
  flex-grow: 1;
  order: 2;
}

.custom-accordion-button:after {
  order: 1;
  margin-right: .5rem;
  background-image: url("../right_arrow.9e893646.svg") !important;
}

.custom-accordion-button:not(.collapsed):after {
  background-image: url("../down_arrow.f68f7da0.svg") !important;
}

.service-box-container input:focus ~ *, .selected-plan {
  color: #fff;
  background-color: #07292b;
}

.service-box-label {
  cursor: pointer;
  padding: .6em 1.3em;
}

.service-summary {
  color: #fff;
  background: #07292b;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.service-summary__text {
  flex-direction: column;
  align-self: center;
  gap: .2em;
  width: 100%;
  display: flex;
}

.service-summary__text p {
  align-self: center;
  margin-left: -1em;
  line-height: 1.4em;
}

.service-summary__text h2 {
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.1em;
}

.service-summary__text small {
  text-align: center;
  width: 82%;
}

.service-summary__title {
  line-height: 1;
}

.service-summary__list {
  margin-left: 1.75em;
}

.service-summary__accordion {
  align-self: center;
  align-items: center;
  gap: .5em;
  width: 90%;
  padding-left: .2em;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
}

.service-summary__accordion a {
  color: #fff;
  text-decoration: none;
}

.busybox {
  pointer-events: none;
  position: relative;
}

.busybox > * {
  filter: blur(3px);
}

.busybox:before {
  content: "";
  z-index: 10000;
  pointer-events: auto;
  cursor: wait;
  background: #ffffff80;
  display: block;
  position: absolute;
  inset: 0;
}

.busybox:after {
  content: "";
  z-index: 10001;
  background-image: url("../rotating-icon.6c591a0e.svg");
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
  animation: 2s linear infinite spin-icon;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes spin-icon {
  from {
    transform: translateX(-50%)translateY(-50%)rotate(0);
  }

  to {
    transform: translateX(-50%)translateY(-50%)rotate(360deg);
  }
}

.card-pinstripe-primary {
  border-top: 5px solid #07292b;
}

.card-pinstripe-secondary {
  border-top: 5px solid #6c757d;
}

.card-pinstripe-success {
  border-top: 5px solid #198754;
}

.card-pinstripe-info {
  border-top: 5px solid #0dcaf0;
}

.card-pinstripe-warning {
  border-top: 5px solid #ffc107;
}

.card-pinstripe-danger {
  border-top: 5px solid #d52e21;
}

.card-pinstripe-light {
  border-top: 5px solid #f8f9fa;
}

.card-pinstripe-dark {
  border-top: 5px solid #212529;
}

.card-pinstripe-accent {
  border-top: 5px solid #f9c939;
}

.check-availability__form {
  max-width: 40em;
}

.data-table {
  width: auto;
}

.data-table th {
  padding-left: 0;
  padding-right: 1em;
}

.dialog-stack {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.dialog-stack .dialog {
  min-width: auto;
  max-width: none;
}

.dialog-stack .dialog:not(:last-child) {
  margin-bottom: 1em;
}

.empty {
  text-align: center;
  color: #6c757d;
  padding: 2em;
  font-size: 1.2em;
}

.form-errors {
  margin: 0;
  padding: 0;
  list-style: none;
}

.form-errors__item {
  color: #fff;
  border-radius: var(--bs-border-radius);
  background: #d52e21;
  margin-bottom: .5em;
  padding: .25em 1em;
}

.header-line {
  text-align: center;
  position: relative;
}

.header-line:before {
  content: "";
  background: #adb5bd;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.header-line span {
  z-index: 1;
  background: #f8f9fa;
  padding: 0 1em;
  position: relative;
}

.inview {
  overflow-x: hidden;
}

.inview-fadein {
  opacity: 0;
}

.inview-fadein.inview-visible {
  opacity: 1;
}

.inview .inview-fadein {
  transition: opacity 1s;
}

.inview .inview-fadein-up, .inview-fadein-down, .inview-fadein-left, .inview-fadein-right {
  transition: opacity .75s, transform .6s;
}

.inview-fadein-right {
  opacity: 0;
  transform: translateX(-35px);
}

.inview-visible .inview-fadein-right, .inview-fadein-right.inview-visible {
  opacity: 1;
  transform: translateX(0);
}

.inview-fadein-left {
  opacity: 0;
  transform: translateX(35px);
}

.inview-visible .inview-fadein-left, .inview-fadein-left.inview-visible {
  opacity: 1;
  transform: translateX(0);
}

.inview-fadein-up {
  opacity: 0;
  transform: translateY(35px);
}

.inview-visible .inview-fadein-up, .inview-fadein-up.inview-visible {
  opacity: 1;
  transform: translateY(0);
}

.inview-fadein-down {
  opacity: 0;
  transform: translateY(-35px);
}

.inview-visible .inview-fadein-down, .inview-fadein-down.inview-visible {
  opacity: 1;
  transform: translateY(0);
}

.inview-delay-0 {
  transition-delay: 0s;
}

.inview-delay-1 {
  transition-delay: .1s;
}

.inview-delay-2 {
  transition-delay: .2s;
}

.inview-delay-3 {
  transition-delay: .3s;
}

.inview-delay-4 {
  transition-delay: .4s;
}

.inview-delay-5 {
  transition-delay: .5s;
}

@media (min-width: 576px) {
  .inview-sm-fadein {
    opacity: 0;
  }

  .inview-sm-fadein.inview-visible {
    opacity: 1;
  }

  .inview .inview-sm-fadein {
    transition: opacity 1s;
  }

  .inview .inview-sm-fadein-up, .inview-sm-fadein-down, .inview-sm-fadein-left, .inview-sm-fadein-right {
    transition: opacity .75s, transform .6s;
  }

  .inview-sm-fadein-right {
    opacity: 0;
    transform: translateX(-35px);
  }

  .inview-visible .inview-sm-fadein-right, .inview-sm-fadein-right.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-sm-fadein-left {
    opacity: 0;
    transform: translateX(35px);
  }

  .inview-visible .inview-sm-fadein-left, .inview-sm-fadein-left.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-sm-fadein-up {
    opacity: 0;
    transform: translateY(35px);
  }

  .inview-visible .inview-sm-fadein-up, .inview-sm-fadein-up.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-sm-fadein-down {
    opacity: 0;
    transform: translateY(-35px);
  }

  .inview-visible .inview-sm-fadein-down, .inview-sm-fadein-down.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-delay-0 {
    transition-delay: 0s;
  }

  .inview-delay-1 {
    transition-delay: .1s;
  }

  .inview-delay-2 {
    transition-delay: .2s;
  }

  .inview-delay-3 {
    transition-delay: .3s;
  }

  .inview-delay-4 {
    transition-delay: .4s;
  }

  .inview-delay-5 {
    transition-delay: .5s;
  }
}

@media (min-width: 768px) {
  .inview-md-fadein {
    opacity: 0;
  }

  .inview-md-fadein.inview-visible {
    opacity: 1;
  }

  .inview .inview-md-fadein {
    transition: opacity 1s;
  }

  .inview .inview-md-fadein-up, .inview-md-fadein-down, .inview-md-fadein-left, .inview-md-fadein-right {
    transition: opacity .75s, transform .6s;
  }

  .inview-md-fadein-right {
    opacity: 0;
    transform: translateX(-35px);
  }

  .inview-visible .inview-md-fadein-right, .inview-md-fadein-right.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-md-fadein-left {
    opacity: 0;
    transform: translateX(35px);
  }

  .inview-visible .inview-md-fadein-left, .inview-md-fadein-left.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-md-fadein-up {
    opacity: 0;
    transform: translateY(35px);
  }

  .inview-visible .inview-md-fadein-up, .inview-md-fadein-up.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-md-fadein-down {
    opacity: 0;
    transform: translateY(-35px);
  }

  .inview-visible .inview-md-fadein-down, .inview-md-fadein-down.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-delay-0 {
    transition-delay: 0s;
  }

  .inview-delay-1 {
    transition-delay: .1s;
  }

  .inview-delay-2 {
    transition-delay: .2s;
  }

  .inview-delay-3 {
    transition-delay: .3s;
  }

  .inview-delay-4 {
    transition-delay: .4s;
  }

  .inview-delay-5 {
    transition-delay: .5s;
  }
}

@media (min-width: 992px) {
  .inview-lg-fadein {
    opacity: 0;
  }

  .inview-lg-fadein.inview-visible {
    opacity: 1;
  }

  .inview .inview-lg-fadein {
    transition: opacity 1s;
  }

  .inview .inview-lg-fadein-up, .inview-lg-fadein-down, .inview-lg-fadein-left, .inview-lg-fadein-right {
    transition: opacity .75s, transform .6s;
  }

  .inview-lg-fadein-right {
    opacity: 0;
    transform: translateX(-35px);
  }

  .inview-visible .inview-lg-fadein-right, .inview-lg-fadein-right.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-lg-fadein-left {
    opacity: 0;
    transform: translateX(35px);
  }

  .inview-visible .inview-lg-fadein-left, .inview-lg-fadein-left.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-lg-fadein-up {
    opacity: 0;
    transform: translateY(35px);
  }

  .inview-visible .inview-lg-fadein-up, .inview-lg-fadein-up.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-lg-fadein-down {
    opacity: 0;
    transform: translateY(-35px);
  }

  .inview-visible .inview-lg-fadein-down, .inview-lg-fadein-down.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-delay-0 {
    transition-delay: 0s;
  }

  .inview-delay-1 {
    transition-delay: .1s;
  }

  .inview-delay-2 {
    transition-delay: .2s;
  }

  .inview-delay-3 {
    transition-delay: .3s;
  }

  .inview-delay-4 {
    transition-delay: .4s;
  }

  .inview-delay-5 {
    transition-delay: .5s;
  }
}

@media (min-width: 1200px) {
  .inview-xl-fadein {
    opacity: 0;
  }

  .inview-xl-fadein.inview-visible {
    opacity: 1;
  }

  .inview .inview-xl-fadein {
    transition: opacity 1s;
  }

  .inview .inview-xl-fadein-up, .inview-xl-fadein-down, .inview-xl-fadein-left, .inview-xl-fadein-right {
    transition: opacity .75s, transform .6s;
  }

  .inview-xl-fadein-right {
    opacity: 0;
    transform: translateX(-35px);
  }

  .inview-visible .inview-xl-fadein-right, .inview-xl-fadein-right.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-xl-fadein-left {
    opacity: 0;
    transform: translateX(35px);
  }

  .inview-visible .inview-xl-fadein-left, .inview-xl-fadein-left.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-xl-fadein-up {
    opacity: 0;
    transform: translateY(35px);
  }

  .inview-visible .inview-xl-fadein-up, .inview-xl-fadein-up.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-xl-fadein-down {
    opacity: 0;
    transform: translateY(-35px);
  }

  .inview-visible .inview-xl-fadein-down, .inview-xl-fadein-down.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-delay-0 {
    transition-delay: 0s;
  }

  .inview-delay-1 {
    transition-delay: .1s;
  }

  .inview-delay-2 {
    transition-delay: .2s;
  }

  .inview-delay-3 {
    transition-delay: .3s;
  }

  .inview-delay-4 {
    transition-delay: .4s;
  }

  .inview-delay-5 {
    transition-delay: .5s;
  }
}

@media (min-width: 1400px) {
  .inview-xxl-fadein {
    opacity: 0;
  }

  .inview-xxl-fadein.inview-visible {
    opacity: 1;
  }

  .inview .inview-xxl-fadein {
    transition: opacity 1s;
  }

  .inview .inview-xxl-fadein-up, .inview-xxl-fadein-down, .inview-xxl-fadein-left, .inview-xxl-fadein-right {
    transition: opacity .75s, transform .6s;
  }

  .inview-xxl-fadein-right {
    opacity: 0;
    transform: translateX(-35px);
  }

  .inview-visible .inview-xxl-fadein-right, .inview-xxl-fadein-right.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-xxl-fadein-left {
    opacity: 0;
    transform: translateX(35px);
  }

  .inview-visible .inview-xxl-fadein-left, .inview-xxl-fadein-left.inview-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .inview-xxl-fadein-up {
    opacity: 0;
    transform: translateY(35px);
  }

  .inview-visible .inview-xxl-fadein-up, .inview-xxl-fadein-up.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-xxl-fadein-down {
    opacity: 0;
    transform: translateY(-35px);
  }

  .inview-visible .inview-xxl-fadein-down, .inview-xxl-fadein-down.inview-visible {
    opacity: 1;
    transform: translateY(0);
  }

  .inview-delay-0 {
    transition-delay: 0s;
  }

  .inview-delay-1 {
    transition-delay: .1s;
  }

  .inview-delay-2 {
    transition-delay: .2s;
  }

  .inview-delay-3 {
    transition-delay: .3s;
  }

  .inview-delay-4 {
    transition-delay: .4s;
  }

  .inview-delay-5 {
    transition-delay: .5s;
  }
}

.page {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.popover-link {
  cursor: pointer;
}

.primary-line {
  border-width: 3px;
  border-color: #07292b;
  max-width: 3rem;
}

.primary-line--left {
  margin-left: 0;
  margin-right: auto;
}

.primary-line--right {
  margin-left: auto;
  margin-right: 0;
}

.primary-line--white {
  border-color: #fff;
}

.primary-line--accent {
  border-color: #f9c939;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-size: 1rem;
}

.text-small {
  font-size: .8em;
}

.text-compact {
  line-height: 1.2;
}

.text-accent {
  color: #f9c939;
}

.please-wait {
  text-align: center;
  color: #6c757d;
  padding: 5em 2em 4em;
  font-size: 2rem;
  position: relative;
}

.please-wait:before {
  content: "";
  z-index: 10001;
  background-image: url("../rotating-icon.6c591a0e.svg");
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
  animation: 2s linear infinite spin-icon;
  display: block;
  position: absolute;
  top: 4em;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%)translateY(-50%);
}

.about-hero {
  background-color: #f8f9fa;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.about-hero__text__container {
  max-width: 90%;
}

@media (min-width: 992px) {
  .about-hero__text__container {
    max-width: 80%;
  }
}

.about-provide {
  text-align: center;
  background-color: #fff;
  padding-top: .2rem;
  padding-bottom: 3rem;
}

.about-provide__container {
  flex-direction: column;
  display: flex;
}

@media (min-width: 992px) {
  .about-provide__container {
    flex-direction: row;
  }
}

.about-provide__title {
  width: 60%;
  margin: auto;
  padding-bottom: 3.3em;
}

@media (min-width: 992px) {
  .about-provide__title {
    text-align: start;
    width: 50%;
    margin: 0;
  }
}

.about-provide__features {
  flex-wrap: wrap;
  gap: 4em;
  display: flex;
}

.about-provide__features img {
  align-self: flex-start;
}

@media (min-width: 576px) {
  .about-provide__features {
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .about-provide__features {
    gap: 2em;
    width: 50%;
  }
}

.about-provide__feature__container {
  text-align: start;
  gap: 20px;
}

.about-provide__feature {
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.origin-story {
  background-color: #fff;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.origin-story__container {
  padding: 1.3em;
}

@media (min-width: 768px) {
  .origin-story__container {
    padding-left: 0;
    display: flex;
  }
}

.origin-story__title__container .origin-story__title {
  text-align: center;
}

@media (min-width: 768px) {
  .origin-story__title__container .origin-story__title {
    text-align: start;
    margin-top: -.2em;
    margin-left: .7em;
  }

  .origin-story__title__container {
    text-align: start;
    width: 50%;
  }

  .origin-story__content {
    width: 50%;
  }
}

.about-wwu {
  background-color: #fff;
}

.check-availability__card__container {
  justify-content: center;
  width: 100%;
  padding-bottom: 5rem;
  display: flex;
}

@media (min-width: 992px) {
  .check-availability__card__container {
    width: auto;
  }
}

.service-promotional {
  justify-content: center;
  width: 100%;
  display: flex;
}

@media (min-width: 576px) {
  .service-promotional {
    width: auto;
  }
}

.service-promotional__container {
  min-width: 355px;
}

@media (min-width: 768px) {
  .service-promotional__container {
    width: 55%;
  }
}

.registration_container {
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .registration_container {
    flex-wrap: nowrap;
  }
}

.bbl {
  float: right;
  background-color: #fff;
  border: 1px solid #000;
  min-width: 250px;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: .5rem;
  font-size: small;
  line-height: 1.4;
}

.bbl a, .bbl a:active, .bbl a:hover, .bbl a:visited {
  color: #007bff;
}

.bbl p {
  margin: 0;
}

.bbl section {
  border-bottom: 2px solid #000;
  margin: 0 0 .5rem;
  padding: 0 0 .25rem;
}

.bbl section h4 {
  margin: 0 0 .1rem;
  font-size: .9rem;
  font-weight: 700;
}

.bbl section h4 span, .bbl section h5 span, .bbl section ul li span:last-of-type, .bbl section p span:last-of-type, .bbl section p a {
  float: right;
  font-weight: 700;
}

.bbl-price-notice {
  margin: 0 0 .5rem;
  font-size: .8rem;
  font-weight: 700;
}

.bbl section h5 {
  margin: 0;
  padding-left: 1em;
  font-size: .8rem;
  font-weight: 400;
}

.bbl section ul {
  margin: 0 0 .5rem;
  padding: 0 0 0 1em;
}

.bbl section ul li {
  padding: 0 0 0 .8em;
  list-style-type: none;
}

.bbl section.header {
  border-bottom-width: 10px;
}

.bbl section.header h1, h2 {
  font-weight: 700;
}

.bbl section.header h1, h2, h3 {
  margin: 0 0 .1rem;
}

.bbl section.header h1 {
  border-bottom: 2px solid #000;
  font-size: 2rem;
}

.bbl section.header h2 {
  font-size: 1.2rem;
}

.bbl section.header h3 {
  font-size: 1rem;
}

.bbl section.header p {
  margin: 0;
}

.bbl section.header p span:last-of-type {
  float: none;
}

.bbl section.billing-period-display {
  border-bottom: 2px solid #000;
  margin: 0 0 .5rem;
  padding: 0 0 .25rem;
}

.bbl section.billing-period-display h4 {
  border-bottom: 5px solid #000;
  margin: 0 0 .1rem;
  font-size: 1rem;
  font-weight: 700;
}

.bbl section.billing-period-display p {
  margin-top: .4em;
}

.bbl section.billing-period-display p span {
  float: none;
  font-weight: 700;
}

.bbl section.billing-period-display p a {
  float: none;
}

.bbl section.additional-charges > h5:nth-of-type(3) {
  margin: 0 0 .5rem;
}

.bbl section.discounts p {
  margin-top: .1rem;
  padding-left: 1em;
}

.bbl section.discounts p a {
  float: none;
}

.bbl section.discounts ul {
  margin: .4em 0 .2em;
}

.bbl section.acp p {
  padding-left: 1em;
}

.bbl section.acp p:last-of-type {
  padding: .2em 0 0 2em;
  font-weight: 700;
}

.bbl section.acp p a {
  float: none;
}

.bbl section.speeds p {
  padding-left: 1em;
}

.bbl section.policies p {
  font-weight: 700;
}

.bbl section.support p {
  margin-top: .2em;
  padding-left: 1em;
}

.bbl section.support p a {
  float: none;
}

.bbl section.footer {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.bbl section.footer p:nth-of-type(2) {
  margin: .2em 0;
}

.bbl section.footer p:nth-of-type(2) a {
  float: right;
}

.contact-card__container {
  justify-content: space-around;
  gap: 1em;
  padding-bottom: 3rem;
}

.contact-section {
  background-color: #f8f9fa;
}

.contact-card {
  background-color: #f8f9fa;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24em;
  height: 10em;
  display: flex;
}

@media (min-width: 768px) {
  .contact-card {
    justify-content: space-evenly;
    height: 12em;
  }
}

.contact-card a, .contact-card button {
  padding: .6em 1.8em;
}

.contact-card .contact-card__chatbtn {
  padding: .6em 4.3em;
}

@media (min-width: 768px) {
  .contact-card .contact-card__chatbtn {
    padding: .6em 1.8em;
  }
}

.contact-card small {
  margin-top: -1.5em;
  margin-bottom: 1em;
  display: block;
}

@media (min-width: 768px) {
  .contact-card small {
    margin-bottom: 0;
  }
}

.contact__img__container {
  display: none;
}

@media (min-width: 992px) {
  .contact__img__container {
    width: 50%;
    display: block;
  }
}

.contact__container {
  width: 100%;
}

@media (min-width: 992px) {
  .contact__container {
    width: 40%;
  }
}

.faq-contact {
  display: none;
}

@media (min-width: 992px) {
  .faq-contact {
    z-index: 5;
    justify-content: flex-end;
    align-items: center;
    gap: .4em;
    width: 25%;
    font-size: .5em;
    transition: none;
    display: flex;
    position: absolute;
    top: 18px;
    right: 90px;
  }
}

.faq-contact-xs {
  justify-content: flex-start;
  gap: .4em;
  width: 100%;
  margin-left: -1em;
  display: flex;
}

.faq-contact-xs a {
  text-decoration: none;
}

@media (min-width: 992px) {
  .faq-contact-xs {
    display: none;
  }

  .faq-header {
    flex-direction: column;
    display: flex;
    position: relative;
  }
}

.residents-hero {
  background-color: #f9c939;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.residents-hero__text__container {
  max-width: 80%;
}

.residents-online {
  text-align: center;
  background-color: #fff;
  padding-bottom: 8rem;
}

.residents-online__features {
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
}

@media (min-width: 576px) {
  .residents-online__features {
    justify-content: center;
    gap: 2em;
  }
}

.residents-online__feature {
  text-align: start;
  gap: 20px;
}

.residents-plans {
  background-color: #f9c939;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.residents-plans__speed {
  font-size: 4em;
  font-weight: bold;
  line-height: 1;
}

.residents-signup {
  background-color: #f9c939;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.residents-signup__text__container {
  max-width: 70%;
}

.residents-signup__form__container {
  padding-top: 3rem;
}

.residents-slider {
  background: #f8f9fa;
  flex-flow: column;
  font-size: 1em;
  display: flex;
  min-height: 100vh !important;
}

@media (min-width: 768px) {
  .residents-slider {
    flex-direction: row;
    font-size: 1.23em;
  }
}

.residents-slider__images {
  background-size: cover;
  align-items: center;
  display: flex;
}

@media (min-width: 576px) {
  .residents-slider__images {
    height: 50%;
  }

  .residents-slider__images img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .residents-slider__images {
    width: 50%;
    height: auto;
  }

  .residents-slider__images img {
    object-fit: cover;
  }
}

.residents-slider__body {
  flex-direction: column;
  justify-content: space-around;
  height: 50%;
  padding: 2em 1em;
  display: flex;
}

@media (min-width: 768px) {
  .residents-slider__body {
    justify-content: space-evenly;
    width: 50%;
    height: auto;
    padding: 2em 1em;
  }
}

@media (min-width: 1200px) {
  .residents-slider__body {
    padding: 4em 1em 2em;
  }
}

@media (min-width: 1400px) {
  .residents-slider__body {
    padding: 5em 1em 2em;
  }
}

.residents-slider__title h2 {
  font-size: 1.5em;
}

@media (min-width: 1200px) {
  .residents-slider__title h2 {
    font-size: 2em;
  }
}

.residents-slider__header {
  font-size: 1.5em;
  display: flex;
}

.residents-slider__header span {
  font-size: .7em;
}

.long-text, .residents-slider__subtitle {
  white-space: normal;
}

.residents-slider__progress .slider-progress-indicator {
  max-width: 4em;
}

.residents-faq {
  background-color: #fff;
  padding-top: 4rem;
}

.hero {
  background: linear-gradient(#07292b66 0%, #07292b33 50%), url("../cityscape1.3a8d5528.jpg") center / cover;
  flex-flow: column;
  justify-content: flex-start;
  height: 80vh;
  min-height: 25em;
  padding-top: 70px;
  font-size: 1.1em;
  display: flex;
}

.hero__container {
  padding: 0;
}

.hero h1 {
  margin-top: 15vh;
  font-size: calc(1.315rem + .78vw);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .hero h1 {
    font-size: 1.9rem;
  }
}

@media (min-width: 992px) {
  .hero h1 {
    font-size: calc(1.425rem + 2.1vw);
  }
}

@media (min-width: 992px) and (min-width: 1200px) {
  .hero h1 {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .hero .signup-form {
    max-width: 35em;
  }
}

.features {
  padding: 4em 0;
}

.and-features {
  background: #fff;
  flex-flow: column;
  font-size: 1em;
  display: flex;
}

@media (min-width: 992px) {
  .and-features {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .and-features {
    flex-direction: row;
  }

  .and-features__image {
    width: 50%;
  }
}

.and-features__body {
  flex-flow: column;
  padding: 1em;
  display: flex;
}

@media (min-width: 768px) {
  .and-features__body {
    width: 50%;
    padding: 2em 1em;
  }
}

@media (min-width: 992px) {
  .and-features__body {
    padding: 3em 1em 2em;
  }
}

@media (min-width: 1200px) {
  .and-features__body {
    padding: 4em 1em 2em;
  }
}

@media (min-width: 1400px) {
  .and-features__body {
    padding: 5em 1em 2em;
  }
}

.and-features__header {
  flex-flow: row;
  align-items: baseline;
  gap: .25em;
  font-size: 1.5em;
  display: flex;
}

.and-features__title {
  flex: 1;
}

.and-features__progress {
  margin-top: 2em;
}

.and-features__progress .slider-progress-indicator {
  max-width: 3em;
}

@media (min-width: 768px) {
  .and-features__progress {
    margin-top: auto;
  }
}

.cta-bridge {
  color: #fff;
  background: linear-gradient(#07292b66 0%, #07292b33 50%), url("../citybridge.e5a8560c.jpg") center / cover;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 7em 0;
  display: flex;
}

@media (min-width: 576px) {
  .cta-bridge {
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .cta-bridge {
    justify-content: center;
  }
}

.cta-bridge .cta-bridge__main_text {
  text-align: center;
  font-size: 2.125em;
  font-weight: 900;
}

.cta-bridge .btn {
  align-items: center;
  height: 3em;
  font-style: italic;
  display: flex;
}

.cta-bridge__residentsbtn {
  color: #f9c939;
}

.cta-bridge__residentsbtn:hover {
  color: #f9c939;
  background-color: #031415;
}

.cta-marquee {
  background: #f9c939;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  display: flex;
}

@media (min-width: 768px) {
  .cta-marquee {
    flex-direction: row;
    align-items: flex-start;
    gap: 1em;
    padding: 2em;
  }
}

.cta-marquee h2 {
  color: #07292b;
  white-space: nowrap;
  font-size: .8em;
  font-weight: bold;
}

@media (min-width: 768px) {
  .cta-marquee h2 {
    font-size: 1em;
  }
}

.cta-marquee__container {
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.cta-marquee__container:before {
  content: "";
  z-index: 1;
  background: linear-gradient(to right, #f9c939 0%, #f9c93900 100%);
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cta-marquee__container:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to left, #f9c939 0%, #f9c93900 100%);
  width: 10vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.cta-marquee__text {
  color: #07292b;
  flex-shrink: 0;
  width: 75%;
  padding: 0 1em;
  font-size: 1.1em;
  position: relative;
}

@media (min-width: 768px) {
  .cta-marquee__text {
    width: 50%;
  }
}

.cta-nextgen {
  background: #07292b;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.cta-nextgen__contact_btn {
  width: 10em;
}

@media (min-width: 992px) {
  .cta-nextgen__contact_btn {
    width: 16em;
  }
}

.buildings-provides, .buildings-residential {
  text-align: center;
  padding-bottom: 8rem;
}

.buildings-provides__features, .buildings-residential__features {
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
}

@media (min-width: 576px) {
  .buildings-provides__features, .buildings-residential__features {
    justify-content: center;
    gap: 2em;
  }
}

.buildings-provides__feature, .buildings-residential__feature {
  text-align: start;
  gap: 20px;
}

.accordion-item {
  border-width: 2px 0;
  background-color: #0000 !important;
  border-radius: 0 !important;
}

.accordion-button {
  background-color: #0000;
}

.buildings-hiw {
  color: #fff;
  background: #07292b;
  height: 100vh;
  padding: 2em 0;
}

@media (min-width: 992px) {
  .buildings-hiw {
    padding: 5em 0;
  }
}

.buildings-hiw__container {
  grid-template: "hiw-anim" 1fr
                 "hiw-content" 1fr
                 / 1fr;
  align-content: center;
  gap: 2em;
  height: 100%;
  display: grid;
  position: relative;
}

@media (min-width: 992px) {
  .buildings-hiw__container {
    grid-template: "hiw-content hiw-anim" 1fr
    / 1fr 1fr;
  }

  .buildings-hiw__animation-container {
    position: absolute;
    top: 50%;
    left: 30%;
    right: -150px;
    transform: translateY(-50%);
  }
}

@media (min-width: 1200px) {
  .buildings-hiw__animation-container {
    right: -300px;
  }
}

@media (min-width: 1400px) {
  .buildings-hiw__animation-container {
    right: -500px;
  }
}

.buildings-hiw__animation {
  grid-area: hiw-anim;
  align-items: flex-end;
  display: flex;
}

@media (min-width: 992px) {
  .buildings-hiw__animation {
    justify-content: center;
    align-items: center;
  }
}

.buildings-hiw__animation canvas {
  width: 100%;
  height: auto;
}

.buildings-hiw__content {
  flex-flow: column;
  grid-area: hiw-content;
  justify-content: center;
  font-size: 1.1em;
  display: flex;
}

@media (min-width: 992px) {
  .buildings-hiw__content {
    font-size: 1.75em;
  }
}

.buildings-hiw__steps {
  font-size: .5em;
}

.buildings-hiw__stepnum {
  width: 1.3em;
  display: inline-block;
}

.buildings-hiw__text .slider-set-item {
  white-space: normal;
  height: 9em;
}

.buildings-hiw__progress .slider-progress-indicator {
  background: #ffffff40;
  max-width: 3em;
}

.buildings-hiw__progress .slider-progress-indicator:before {
  background: #fff;
}

.buildings-faq, .buildings-provides, .buildings-residential {
  background-color: #fff;
}

.contact-submitbtn {
  width: 50%;
}

@media (min-width: 576px) {
  .contact-submitbtn {
    width: 35%;
  }
}

.registration {
  padding-bottom: 4rem;
}

#password-validation-feedback {
  padding-left: 0;
  list-style: none;
}

#password-validation-feedback li {
  gap: .5em;
  display: flex;
}

.registration-form-container {
  width: 100%;
}

@media (min-width: 992px) {
  .registration-form-container {
    width: 55%;
  }
}

.service-address-plan {
  width: 100%;
}

@media (min-width: 992px) {
  .service-address-plan {
    width: 22em;
  }
}

.registration-checkpoint {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  display: flex;
}

@media (min-width: 992px) {
  .customer-network-success-container {
    width: 55%;
  }
}

.signup-complete-table {
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  padding: 1em;
}

.signup-complete-table th {
  text-align: left;
  background-color: #fff;
  padding: .5em 1em;
}

.signup-complete-table td {
  background-color: #fff;
  padding: .5em 1em;
}

.signup-complete-table tr:last-child td {
  border-bottom: none;
}

@media (min-width: 992px) {
  .signup-complete-table {
    width: 30%;
  }
}

.signup-complete-refer-card {
  color: #fff;
  background-color: #07292b;
  height: 12em;
}

.signup-complete-refer-card .signup-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .6em;
  height: 100%;
  display: flex;
}

.signup-complete-refer-card__title {
  margin-bottom: -.5em;
  font-size: 1.8em;
  font-weight: bold;
}

.signup-complete-refer-card__cta a {
  padding: .7em 1.6em;
  font-size: .7em;
}

.unavailable-text__container {
  text-align: center;
}

@media (min-width: 992px) {
  .unavailable-text__container {
    text-align: start;
  }
}

.button-group {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1em;
  margin-left: -.25em;
  margin-right: -.25em;
  display: flex;
}

.button-group .btn {
  margin-bottom: .5em;
  margin-left: .25em;
  margin-right: .25em;
}

.button-group.-right {
  justify-content: flex-end;
}

.button-group.-nowrap {
  flex-flow: row;
}

.button-group.-nowrap .btn {
  margin-bottom: 0;
}

.hijack {
  z-index: 20000;
  position: fixed;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.hijack-notification {
  color: #000;
  background-color: #ffe761;
  background-image: linear-gradient(135deg, #0000000a 25%, #0000 25% 50%, #0000000a 50% 75%, #0000 75%, #0000);
  background-size: 50px 50px;
  border-radius: 3px;
  flex-flow: row;
  align-items: center;
  padding: .5em;
  font-size: 1rem;
  display: flex;
  box-shadow: 0 0 .5em #0000004d;
}

@media (prefers-color-scheme: dark) {
  .hijack-notification {
    color: #fff;
    background: #333;
  }
}

.hijack-message {
  flex: auto;
  margin-right: 2em;
}

.hijack-actions {
  flex: 0 auto;
  margin: -.25em;
  display: flex;
}

.hijack-button {
  color: #fff;
  background-color: #66572c;
  margin: .25em;
}

.hijack-button:hover {
  background-color: #8a753b;
}

.public-wifi-about {
  text-align: center;
  grid-template-areas: "title"
                       "subtitle"
                       "cta";
  display: grid;
}

@media (min-width: 576px) {
  .public-wifi-about {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "title cta"
                         "subtitle cta";
  }
}

.public-wifi-about__title {
  color: #495057;
  grid-area: title;
  font-size: 1.1em;
}

.public-wifi-about__subtitle {
  color: #495057;
  grid-area: subtitle;
  margin-bottom: 1em;
  font-family: Montserrat, Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .9em;
}

@media (min-width: 576px) {
  .public-wifi-about__subtitle {
    margin-bottom: 0;
  }
}

.public-wifi-about__cta {
  grid-area: cta;
  place-self: center;
}

@media (min-width: 768px) {
  .public-wifi-about__cta {
    margin-left: 1em;
  }
}

.public-wifi-grid {
  grid-gap: 1em;
  text-align: center;
  grid-template-areas: "prompt"
                       "message"
                       "about";
  display: grid;
}

.public-wifi-grid .dialog {
  width: 100%;
  min-width: auto;
  max-width: none;
  height: 100%;
}

.public-wifi-grid__prompt {
  grid-area: prompt;
}

.public-wifi-grid__about {
  grid-area: about;
}

.public-wifi-grid__property-manager {
  grid-area: pm;
}

.public-wifi-grid__message {
  grid-area: message;
}

.public-wifi-grid__messagetext p:last-child {
  margin-bottom: 0;
}

.public-wifi-pm {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.public-wifi-pm__title {
  color: #495057;
  font-size: 1.1em;
}

.public-wifi-pm__name {
  color: #495057;
  margin-bottom: 2rem;
  font-size: 1.2em;
  font-weight: bold;
}

.public-wifi-pm__logo {
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .public-wifi-pm__logo {
    max-height: 100px;
  }
}

@media (min-width: 992px) {
  .public-wifi-pm__logo {
    max-height: 150px;
  }
}

.signup-box {
  text-align: center;
  grid-template-areas: "title"
                       "subtitle"
                       "cta";
  display: grid;
}

@media (min-width: 576px) {
  .signup-box {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "title cta"
                         "subtitle cta";
  }
}

.signup-box__title {
  color: #495057;
  grid-area: title;
  font-size: 1.2em;
}

.signup-box__subtitle {
  color: #495057;
  grid-area: subtitle;
  margin-bottom: 1em;
  font-family: Montserrat, Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .9em;
  font-weight: bold;
}

@media (min-width: 576px) {
  .signup-box__subtitle {
    margin-bottom: 0;
  }
}

.signup-box__cta {
  grid-area: cta;
  place-self: center;
}

@media (min-width: 768px) {
  .signup-box__cta {
    margin-left: 1em;
  }
}

.terms-of-service {
  height: 100%;
}

.terms-of-service__notice {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 0;
  padding-bottom: 1em;
}

.terms-of-service__terms {
  min-height: 15em;
  max-height: calc(100% - 11em - 40px);
  padding: 1em 0;
  overflow: auto;
}

.terms-of-service__terms.-withemail {
  max-height: calc(100% - 15em - 40px);
}

.terms-of-service__form {
  border-top: 1px solid #dee2e6;
  padding-top: 1em;
}

@media (min-width: 576px) {
  .support-options {
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media (min-width: 992px) {
  .support-options {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.support-options__card {
  margin-bottom: 1em;
}

@media (min-width: 576px) {
  .support-options__chat {
    grid-column: 1 / 3;
  }
}

@media (min-width: 992px) {
  .support-options__chat {
    grid-column: auto;
  }
}

/*# sourceMappingURL=base.css.map */
