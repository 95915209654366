@use "base/variables";
@use "base/mixins";

.hero {
    height: 80vh;
    min-height: 25em;
    padding-top: variables.$main-header-height;
    background:
        linear-gradient(
            to bottom,
            rgba(variables.$primary, 0.4) 0%,
            rgba(variables.$primary, 0.2) 50%
        ),
        url("/assets/backgrounds/cityscape1.jpg") center / cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    font-size: 1.1em;

    &__container {
        padding: 0;
    }

    h1 {
        margin-top: 15vh;
        @include mixins.font-size(1.9rem);
        font-weight: 600;

        @include mixins.media-breakpoint-up(lg) {
            @include mixins.font-size(3rem);
        }
    }

    @include mixins.media-breakpoint-up(lg) {
        .signup-form {
            max-width: 35em;
        }
    }
}

.features {
    padding: 4em 0;
}

.and-features {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1em;
    background: variables.$white;

    @include mixins.media-breakpoint-up(lg) {
        font-size: 1.5em;
    }

    @include mixins.media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.and-features__image {
    @include mixins.media-breakpoint-up(md) {
        width: 50%;
    }
}

.and-features__body {
    display: flex;
    flex-flow: column nowrap;
    padding: 1em;

    @include mixins.media-breakpoint-up(md) {
        width: 50%;
        padding: 2em 1em;
    }

    @include mixins.media-breakpoint-up(lg) {
        padding: 3em 1em 2em;
    }

    @include mixins.media-breakpoint-up(xl) {
        padding: 4em 1em 2em;
    }

    @include mixins.media-breakpoint-up(xxl) {
        padding: 5em 1em 2em;
    }
}

.and-features__header {
    display: flex;
    flex-flow: row nowrap;
    font-size: 1.5em;
    gap: 0.25em;
    align-items: baseline;
}

.and-features__title {
    flex: 1;
}

.and-features__progress {
    margin-top: 2em;

    .slider-progress-indicator {
        max-width: 3em;
    }

    @include mixins.media-breakpoint-up(md) {
        margin-top: auto;
    }
}

.cta-bridge {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: variables.$white;
    padding: 7em 0 7em 0;
    background:
        linear-gradient(
            to bottom,
            rgba(7, 41, 43, 0.4) 0%,
            rgba(7, 41, 43, 0.2) 50%
        ),
        url("/assets/backgrounds/citybridge.jpg") center / cover;

    @include mixins.media-breakpoint-up(sm) {
        justify-content: center;
    }

    @include mixins.media-breakpoint-up(md) {
        justify-content: center;
    }

    .cta-bridge__main_text {
        text-align: center;
        font-size: 2.125em;
        font-weight: 900;
    }

    .btn {
        display: flex;
        font-style: italic;
        align-items: center;
        height: 3em;
    }
}

.cta-bridge__residentsbtn {
    color: variables.$accent;

    &:hover {
        background-color: darken(#07292b, 5%);
        color: variables.$accent;
    }
}

.cta-marquee {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: variables.$accent;
    padding: 2em 0;

    @include mixins.media-breakpoint-up(md) {
        flex-direction: row;
        gap: 1em;
        align-items: flex-start;
        padding: 2em;
    }

    h2 {
        font-size: 0.8em;
        font-weight: bold;
        color: variables.$primary;
        white-space: nowrap;

        @include mixins.media-breakpoint-up(md) {
            font-size: 1em;
        }
    }
}

.cta-marquee__container {
    overflow: hidden;
    display: flex;
    position: relative;
    width: 100%;

    // Fades at the edges
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10vw;
        height: 100%;
        background: linear-gradient(
            to right,
            rgba(variables.$accent, 1) 0%,
            rgba(variables.$accent, 0) 100%
        );
        z-index: 1;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 10vw;
        height: 100%;
        background: linear-gradient(
            to left,
            rgba(variables.$accent, 1) 0%,
            rgba(variables.$accent, 0) 100%
        );
        z-index: 1;
    }
}

.cta-marquee__text {
    width: 75%;
    position: relative;
    flex-shrink: 0;
    color: variables.$primary;
    font-size: 1.1em;
    padding: 0 1em;

    @include mixins.media-breakpoint-up(md) {
        width: 50%;
    }
}
