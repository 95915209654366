@use "base/variables";

.terms-of-service {
    height: 100%;
}

.terms-of-service__notice {
    border-bottom: 1px solid variables.$gray-300;
    padding-bottom: 1em;
    margin-bottom: 0;
}

.terms-of-service__terms {
    min-height: 15em;
    max-height: calc(
        100% - 3em - 8em - 40px
    ); // 100% - notice - form - mobile control bar. This could be done better in JS
    overflow: auto;
    padding: 1em 0;

    &.-withemail {
        max-height: calc(
            100% - 3em - 12em - 40px
        ); // 100% - notice - form - mobile control bar. This could be done better in JS
    }
}

.terms-of-service__form {
    border-top: 1px solid variables.$gray-300;
    padding-top: 1em;
}
