@use "base/variables";
@use "base/mixins";

.registration {
    padding-bottom: variables.$spacer * 4;
}

#password-validation-feedback {
    list-style: none;
    padding-left: 0;
    li {
        display: flex;
        gap: 0.5em;
    }
}

.registration-form-container {
    width: 100%;
    @include mixins.media-breakpoint-up(lg) {
        width: 55%;
    }
}

.service-address-plan {
    width: 100%;

    @include mixins.media-breakpoint-up(lg) {
        width: 22em;
    }
}

.service-address-plan-container {
}
