@use "base/variables";
@use "base/mixins.scss";

.main-content {
}

.main-content--fullscreen {
    height: calc(100vh - #{variables.$main-header-height});
}

.main-content--dialog {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

.main-content--background {
    background:
        linear-gradient(
            to bottom,
            rgba(variables.$primary, 0.4) 0%,
            rgba(variables.$primary, 0.2) 50%
        ),
        url("/assets/backgrounds/cityscape2.jpg") center / cover no-repeat;
}

.main-content--parallax {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 1px;
}
