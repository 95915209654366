@use "base/variables";
@use "base/mixins";

.about-hero {
    background-color: variables.$gray-100;
    padding-top: variables.$spacer * 4;
    padding-bottom: variables.$spacer * 2;
}

.about-hero__text__container {
    max-width: 90%;

    @include mixins.media-breakpoint-up(lg) {
        max-width: 80%;
    }
}

.about-provide {
    text-align: center;
    background-color: variables.$white;
    padding-top: variables.$spacer * 0.2;
    padding-bottom: variables.$spacer * 3;
}

.about-provide__container {
    display: flex;
    flex-direction: column;

    @include mixins.media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.about-provide__title {
    margin: auto;
    padding-bottom: 3.3em;
    width: 60%;

    @include mixins.media-breakpoint-up(lg) {
        text-align: start;
        width: 50%;
        margin: 0;
    }
}

.about-provide__features {
    display: flex;
    gap: 4em;
    flex-wrap: wrap;

    img {
        align-self: flex-start;
    }

    @include mixins.media-breakpoint-up(sm) {
        justify-content: center;
    }

    @include mixins.media-breakpoint-up(lg) {
        gap: 2em;
        width: 50%;
    }
}

.about-provide__feature__container {
    gap: 20px;
    text-align: start;
}

.about-provide__feature {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.origin-story {
    padding-top: variables.$spacer * 3;
    padding-bottom: variables.$spacer * 4;
    background-color: variables.$white;
}

.origin-story__container {
    padding: 1.3em;
    @include mixins.media-breakpoint-up(md) {
        display: flex;
        padding-left: 0;
    }
}

.origin-story__title__container {
    .origin-story__title {
        text-align: center;
        @include mixins.media-breakpoint-up(md) {
            text-align: start;
            margin-left: 0.7em;
            margin-top: -0.2em;
        }
    }

    @include mixins.media-breakpoint-up(md) {
        width: 50%;
        text-align: start;
    }
}

.origin-story__content {
    @include mixins.media-breakpoint-up(md) {
        width: 50%;
    }
}

.about-wwu {
    background-color: variables.$white;
}
