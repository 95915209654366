@use "base/variables";
@use "base/mixins";

.unavailable-text__container {
    text-align: center;

    @include mixins.media-breakpoint-up(lg) {
        text-align: start;
    }
}
