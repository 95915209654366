@use "base/variables";
@use "base/mixins.scss";

.dialog {
    height: 64vh;
    display: flex;
    align-items: center;
    a {
        text-decoration: none;
    }

    min-width: 90%;
    max-width: 90%;

    @include mixins.media-breakpoint-up(sm) {
        min-width: 35rem;
        max-width: 70%;
    }

    &.-fixed {
        @include mixins.media-breakpoint-up(sm) {
            min-width: 80%;
            max-width: 80%;
        }
        @include mixins.media-breakpoint-up(md) {
            min-width: 70%;
            max-width: 70%;
        }
        @include mixins.media-breakpoint-up(lg) {
            min-width: 60%;
            max-width: 60%;
        }
    }
}

.dialog-form-container {
    width: 28em;
    @include mixins.media-breakpoint-up(lg) {
        width: 35em;
    }
}

.card-body {
    min-width: 100%;
    // box-shadow: variables.$box-shadow-lg;
}

.dialog.card .card-body.text-center {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: auto;
}

.card-pinstripe-primary {
    border-top: 5px solid variables.$primary;
    height: auto !important;
}
