.data-table {
    // This is a special addon for tables to present two columns of data, <th> for the key, <td> for the value
    // Example:
    //     <table class="table table-sm table-borderless data-table">
    //         <tr>
    //             <th>Key</th>
    //             <td>Value</th>
    //         </tr>
    //     </table>
    width: auto;
    th {
        padding-left: 0;
        padding-right: 1em;
    }
}
