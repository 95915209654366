@use "base/variables";
@use "base/mixins";

.check-availability__card__container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: variables.$spacer * 5;

    @include mixins.media-breakpoint-up(lg) {
        width: auto;
    }
}

.service-promotional {
    width: 100%;
    display: flex;
    justify-content: center;

    @include mixins.media-breakpoint-up(sm) {
        width: auto;
    }
}

    .service-promotional__container {
        @include mixins.media-breakpoint-up(md){
            width: 55%;
        }
        min-width: 355px;
    }

.registration_container {
    flex-wrap: wrap;

    @include mixins.media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }
}
