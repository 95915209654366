// Cover the marked element with a busy indicator
.busybox {
    position: relative;
    pointer-events: none;
}

// Blur all child elements. This does not affect our ::before and ::after pseudo-elements.
.busybox > * {
    filter: blur(3px);
}

.busybox::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    pointer-events: auto;
    cursor: wait;
    background: rgba(255, 255, 255, 0.5);
}

.busybox::after {
    display: block;
    content: "";
    z-index: 10001;
    background-image: url("/assets/images/rotating-icon.svg");
    background-size: 50px 50px;
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: spin-icon 2s linear infinite;
}

@keyframes spin-icon {
    from {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}
