@use "base/variables";
@use "base/mixins";

.faq-contact {
    display: none;

    @include mixins.media-breakpoint-up(lg) {
        font-size: 0.5em;
        display: flex;
        gap: 0.4em;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 18;
        right: 90;
        z-index: 5;
        width: 25%;
        transition: none;
    }
}

.faq-contact-xs {
    justify-content: flex-start;
    gap: 0.4em;
    display: flex;
    width: 100%;
    margin-left: -1em;
    a {
        text-decoration: none;
    }

    @include mixins.media-breakpoint-up(lg) {
        display: none;
    }
}

.faq-header {
    @include mixins.media-breakpoint-up(lg) {
        position: relative;
        display: flex;
        flex-direction: column;
    }
}
