@use "base/variables";
@use "base/mixins";

.residents-hero {
    background-color: variables.$accent;
    padding-top: variables.$spacer * 4;
    padding-bottom: variables.$spacer * 2;
}

.residents-hero__text__container {
    max-width: 80%;
}

.residents-online {
    text-align: center;
    background-color: variables.$white;
    padding-bottom: variables.$spacer * 8;
}

.residents-online__features {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;

    @include mixins.media-breakpoint-up(xs) {
        gap: 30px;
    }

    @include mixins.media-breakpoint-up(sm) {
        justify-content: center;
        gap: 2em;
    }
}

.residents-online__feature {
    @include mixins.media-breakpoint-up(xs) {
        gap: 20px;
        text-align: start;
    }
}

.residents-plans {
    background-color: variables.$accent;
    padding-bottom: variables.$spacer * 2;
    padding-top: variables.$spacer * 2;
}

.residents-plans__speed {
    font-size: 4em;
    font-weight: bold;
    line-height: 1;
}

.residents-signup {
    background-color: variables.$accent;
    padding-top: variables.$spacer * 2;
    padding-bottom: variables.$spacer * 2;
}

.residents-signup__text__container {
    max-width: 70%;
}

.residents-signup__form__container {
    padding-top: 3rem;
}

.residents-slider {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1em;
    background: variables.$gray-100;
    min-height: 100vh !important;

    @include mixins.media-breakpoint-up(md) {
        font-size: 1.23em;
        flex-direction: row;
    }
}

.residents-slider__images {
    background-size: cover;
    display: flex;
    align-items: center;

    @include mixins.media-breakpoint-up(sm) {
        height: 50%;

        img {
            width: 100%;
        }
    }

    @include mixins.media-breakpoint-up(md) {
        width: 50%;
        height: auto;

        img {
            object-fit: cover;
        }
    }
}

.residents-slider__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    padding: 2em 1em;

    @include mixins.media-breakpoint-up(md) {
        width: 50%;
        justify-content: space-evenly;
        height: auto;
        padding: 2em 1em 2em;
    }

    @include mixins.media-breakpoint-up(xl) {
        padding: 4em 1em 2em;
    }

    @include mixins.media-breakpoint-up(xxl) {
        padding: 5em 1em 2em;
    }
}

.residents-slider__title {
    h2 {
        font-size: 1.5em;

        @include mixins.media-breakpoint-up(xl) {
            font-size: 2em;
        }
    }
}

.residents-slider__header {
    display: flex;
    font-size: 1.5em;
    span {
        font-size: 0.7em;
    }
}

.long-text {
    white-space: normal;
}

.residents-slider__subtitle {
    white-space: normal;
}

.residents-slider__progress {
    .slider-progress-indicator {
        max-width: 4em;
    }
}

.residents-faq {
    padding-top: variables.$spacer * 4;
    background-color: variables.$white;
}
