@use "base/variables";

.hijack {
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20000;

    &-notification {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.5em;
        border-radius: 3px;
        font-size: 1rem;
        color: #000;
        background-size: 50px 50px;
        background-color: #ffe761;
        background-image: -webkit-linear-gradient(
            -45deg,
            rgba(0, 0, 0, 0.04) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0, 0, 0, 0.04) 50%,
            rgba(0, 0, 0, 0.04) 75%,
            transparent 75%,
            transparent
        );
        background-image: -moz-linear-gradient(
            -45deg,
            rgba(0, 0, 0, 0.04) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0, 0, 0, 0.04) 50%,
            rgba(0, 0, 0, 0.04) 75%,
            transparent 75%,
            transparent
        );
        background-image: linear-gradient(
            135deg,
            rgba(0, 0, 0, 0.04) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0, 0, 0, 0.04) 50%,
            rgba(0, 0, 0, 0.04) 75%,
            transparent 75%,
            transparent
        );
        box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3);

        @media (prefers-color-scheme: dark) {
            background: #333;
            color: white;
        }
    }

    &-message {
        flex: 1 1 auto;
        margin-right: 2em;
    }

    &-actions {
        display: flex;
        flex: 0 1 auto;
        margin: -0.25em;
    }

    &-button {
        background-color: #66572c;
        color: variables.$white;
        margin: 0.25em;

        &:hover {
            background-color: lighten(#66572c, 10%);
        }
    }
}
