@use "base/variables";
@use "base/mixins.scss";

$opacity-duration: 0.75s;
$translate-duration: 0.6s;

.inview {
    overflow-x: hidden;
}

@each $breakpoint in map-keys(variables.$grid-breakpoints) {
    @include mixins.media-breakpoint-up($breakpoint) {
        $infix: mixins.breakpoint-infix(
            $breakpoint,
            variables.$grid-breakpoints
        );

        .inview#{$infix}-fadein {
            opacity: 0;
            &.inview-visible {
                opacity: 1;
            }
            .inview & {
                transition: opacity 1s;
            }
        }

        .inview .inview#{$infix}-fadein-up,
        .inview#{$infix}-fadein-down,
        .inview#{$infix}-fadein-left,
        .inview#{$infix}-fadein-right {
            transition:
                opacity $opacity-duration,
                transform $translate-duration;
        }

        .inview#{$infix}-fadein-right {
            opacity: 0;
            transform: translateX(-35px);
            .inview-visible &,
            &.inview-visible {
                opacity: 1;
                transform: translateX(0);
            }
        }

        .inview#{$infix}-fadein-left {
            opacity: 0;
            transform: translateX(35px);
            .inview-visible &,
            &.inview-visible {
                opacity: 1;
                transform: translateX(0);
            }
        }

        .inview#{$infix}-fadein-up {
            opacity: 0;
            transform: translateY(35px);
            .inview-visible &,
            &.inview-visible {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .inview#{$infix}-fadein-down {
            opacity: 0;
            transform: translateY(-35px);
            .inview-visible &,
            &.inview-visible {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .inview-delay-0 {
            transition-delay: 0s;
        }

        .inview-delay-1 {
            transition-delay: 0.1s;
        }

        .inview-delay-2 {
            transition-delay: 0.2s;
        }

        .inview-delay-3 {
            transition-delay: 0.3s;
        }

        .inview-delay-4 {
            transition-delay: 0.4s;
        }

        .inview-delay-5 {
            transition-delay: 0.5s;
        }
    }
}
