.dialog-stack {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;

    .dialog {
        min-width: auto;
        max-width: none;
    }
    .dialog:not(:last-child) {
        margin-bottom: 1em;
    }
}
