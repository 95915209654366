@use "base/variables";
@use "base/mixins";

.partners-marquee {
    display: flex;
    flex-direction: column;
    position: relative;

    @include mixins.media-breakpoint-up(md) {
        flex-direction: row;
        gap: 1em;
        align-items: center;
        padding: 2em;
    }

    h2 {
        font-size: 0.8em;
        font-weight: bold;
        text-align: center;
        color: variables.$primary;
        white-space: nowrap;
        padding-bottom: 1em;

        @include mixins.media-breakpoint-up(sm) {
            padding-left: 2em;
            padding-bottom: 0;
            font-size: 1em;
        }
    }
}

.partners-marquee__container {
    overflow: hidden;
    display: flex;
    position: relative;
    width: 100%;

    // Fades at the edges
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10vw;
        height: 100%;
        background: linear-gradient(
            to right,
            rgba(247, 247, 247, 1) 0%,
            rgba(247, 247, 247, 0) 100%
        );
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 10vw;
        height: 100%;
        background: linear-gradient(
            to left,
            rgba(247, 247, 247, 1) 0%,
            rgba(247, 247, 247, 0) 100%
        );
        z-index: 1;
    }
}

.partners-marquee__img {
    position: relative;
    flex-shrink: 0;
    width: 12em;
    height: 6em;
    padding: 0 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 100%;
    }
}
