@use "base/variables";
@use "base/mixins";

.contact-card__container {
    justify-content: space-around;
    gap: 1em;
    padding-bottom: variables.$spacer * 3;
}

.contact-section {
    background-color: variables.$gray-100;
}

.contact-card {
    background-color: variables.$gray-100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10em;
    width: 24em;

    @include mixins.media-breakpoint-up(md) {
        justify-content: space-evenly;
        height: 12em;
    }

    a,
    button {
        padding: 0.6em 1.8em;
    }

    .contact-card__chatbtn {
        padding: 0.6em 4.3em;

        @include mixins.media-breakpoint-up(md) {
            padding: 0.6em 1.8em;
        }
    }

    small {
        display: block;
        margin-top: -1.5em;
        margin-bottom: 1em;

        @include mixins.media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}

.contact__img__container {
    display: none;

    @include mixins.media-breakpoint-up(lg) {
        display: block;
        width: 50%;
    }
}

.contact__container {
    width: 100%;

    @include mixins.media-breakpoint-up(lg) {
        width: 40%;
    }
}
