@use "base/variables";
@use "base/mixins";

.service-summary {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: variables.$primary;
    color: variables.$white;
}

.service-summary__text {
    align-self: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    p {
        line-height: 1.4em;
        margin-left: -1em;
        align-self: center;
    }

    h2 {
        padding-left: 1em;
        padding-right: 1em;
        text-align: left;
        font-size: 1.1em;
    }

    small {
        width: 82%;
        text-align: center;
    }
}

.service-summary__title {
    line-height: 1;
}

.service-summary__list {
    margin-left: 1.75em;
}

.service-summary__accordion {
    width: 90%;
    align-self: center;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-left: 0.2em;
    font-size: 1.2em;
    gap: 0.5em;

    a {
        text-decoration: none;
        color: variables.$white;
    }
}
