@use "base/variables";

.text-bold {
    font-weight: bold;
}

.text-normal {
    font-size: 1rem;
}

.text-small {
    font-size: 0.8em;
}

.text-compact {
    line-height: 1.2;
}

.text-accent {
    color: variables.$accent;
}
