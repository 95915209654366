@use "base/variables.scss";

// Classes for the slider component. See 'slider.js' for documentation.

// A set of slider items. This is the container for each set of items. There can be any number of them.
.slider-set {
    position: relative;
    overflow: hidden;
}

// The default state of the slider items is to be absolutely positioned below the active item.
.slider-set-item {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;
    white-space: nowrap;
}

// The active item is the one shown on top.
.slider-set-item.active {
    position: relative;
    z-index: 1;
    opacity: 1;
    //transform: translateY(50%);
}

// The default slider progress indicators
.slider-progress {
    display: flex;
    flex-flow: row nowrap;
    gap: 2px;
}

// Each indicator for the currently shown item in all sets
.slider-progress-indicator {
    position: relative;
    flex: 1;
    height: 0.125rem;
    background: rgba(variables.$gray-900, 0.25);
    --progress: 0%;

    // Animate the bar filling by changing the `--progress` variable
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: var(--progress);
        height: 100%;
        background: variables.$gray-900;
    }
}
