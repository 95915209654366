.button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -0.25em;
    margin-right: -0.25em;
    margin-bottom: 1em;

    .btn {
        margin-left: 0.25em;
        margin-right: 0.25em;
        margin-bottom: 0.5em;
    }
}

.button-group.-right {
    justify-content: flex-end;
}

.button-group.-nowrap {
    flex-flow: row nowrap;

    .btn {
        margin-bottom: 0;
    }
}
