@use "base/variables";
@use "base/mixins";

.cta-nextgen {
    background: variables.$primary;
    padding-top: variables.$spacer * 4;
    padding-bottom: variables.$spacer * 2;
}

.cta-nextgen__contact_btn {
    width: 10em;

    @include mixins.media-breakpoint-up(lg) {
        width: 16em;
    }
}

.buildings-provides,
.buildings-residential {
    text-align: center;
    padding-bottom: variables.$spacer * 8;
}

.buildings-provides__features,
.buildings-residential__features {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;

    @include mixins.media-breakpoint-up(xs) {
        gap: 30px;
    }

    @include mixins.media-breakpoint-up(sm) {
        justify-content: center;
        gap: 2em;
    }
}

.buildings-provides__feature,
.buildings-residential__feature {
    @include mixins.media-breakpoint-up(xs) {
        gap: 20px;
        text-align: start;
    }
}

.accordion-item {
    border-width: 2px 0px 2px 0px;
    border-radius: 0px !important;
    background-color: transparent !important;
}

.accordion-button {
    background-color: transparent;
}

.buildings-hiw {
    height: 100vh;
    padding: 2em 0;
    background: variables.$primary;
    color: variables.$white;

    @include mixins.media-breakpoint-up(lg) {
        padding: 5em 0;
    }
}

.buildings-hiw__container {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "hiw-anim" "hiw-content";
    align-content: center;
    gap: 2em;

    @include mixins.media-breakpoint-up(lg) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "hiw-content hiw-anim";
    }
}

.buildings-hiw__animation-container {
    @include mixins.media-breakpoint-up(lg) {
        position: absolute;
        top: 50%;
        right: -150px;
        left: 30%;
        transform: translateY(-50%);
    }

    @include mixins.media-breakpoint-up(xl) {
        right: -300px;
    }

    @include mixins.media-breakpoint-up(xxl) {
        right: -500px;
    }
}

.buildings-hiw__animation {
    grid-area: hiw-anim;
    display: flex;
    align-items: flex-end;

    @include mixins.media-breakpoint-up(lg) {
        justify-content: center;
        align-items: center;
    }

    canvas {
        width: 100%;
        height: auto;
    }
}

.buildings-hiw__content {
    grid-area: hiw-content;
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.1em;
    justify-content: center;

    @include mixins.media-breakpoint-up(lg) {
        font-size: 1.75em;
    }
}

.buildings-hiw__steps {
    font-size: 0.5em;
}

.buildings-hiw__stepnum {
    display: inline-block;
    width: 1.3em;
}

.buildings-hiw__text {
    .slider-set-item {
        white-space: normal;
        height: 9em;
    }
}

.buildings-hiw__progress {
    .slider-progress-indicator {
        max-width: 3em;
        background: rgba(variables.$white, 0.25);

        &:before {
            background: variables.$white;
        }
    }
}

.buildings-faq,
.buildings-provides,
.buildings-residential {
    background-color: variables.$white;
}

.contact-submitbtn {
    width: 50%;

    @include mixins.media-breakpoint-up(sm) {
        width: 35%;
    }
}
