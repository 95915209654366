@use "base/variables.scss";

.btn-accent {
    background: variables.$accent;
    color: variables.$primary;

    &:hover,
    &:focus {
        background-color: darken(variables.$accent, 5%);
        color: variables.$primary;
    }
}

.header-accent_button {
    @extend .btn-accent;
}

.btn-outline-accent {
    color: #9e7700;
    background-color: transparent;
    background-image: none;
    border-color: variables.$accent;

    &:hover {
        background-color: variables.$accent;
        color: variables.$accent-dark;
    }
}
